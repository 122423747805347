import useContactStore, { toggleReadMessage } from '../../App/stores/ContactStore';
import { formatDate } from '../../Utility/UtilityFunctions';
import { Tooltip } from '@mui/material';
import CommonImage from '../../Components/Image/CommonImage';

export default function MessageItem({ data }) {
    const { setSelectedMessageID, setShowReplyMessage, setMessageUserID, paginationUrl } = useContactStore();

    return (
        <tr
            onClick={async () => {
                if (data?.is_seen === 0) await toggleReadMessage(data?.id, paginationUrl);
                setShowReplyMessage(true);
                setSelectedMessageID(data?.id);
                setMessageUserID(data?.user_id);
            }}
            className={`border-collapse cursor-pointer border-b-[1px] border-cNmSelect hover:bg-cGridView overflow-hidden`}
        >
            <td className={`max-w-[230px] min-w-[230px] truncate
              items-left border-r-[1px] text-left p-s10 ${data?.is_seen === 1 ? "font-normal" : "font-semibold"}`}>
                <div className='flex items-center'>
                    <Tooltip title={data?.is_seen === 0 ? "Not Seen" : data?.reply === null ? "Not Replied" : ""} >
                        <div className={` rounded-full max-w-[10px] min-w-[10px] min-h-[10px] max-h-[10px]  ${data?.is_seen === 0 ? "bg-cLink" : data?.reply === null ? "bg-cIcon" : "bg-white"} `}></div></Tooltip>
                    <div className='max-w-[40px] min-w-[40px] h-s40'>
                        <CommonImage
                            src={data?.user?.image}
                            alt="" className='w-[40px] h-[40px] rounded-full object-cover'
                        />
                    </div>

                    <div className='flex flex-col pl-s10 truncate'>
                        <Tooltip title={data?.user?.name}>
                            <span className='  truncate'>{data?.user?.name}</span>
                        </Tooltip>

                        <Tooltip title={data?.user?.email}>
                            <span className='  truncate'>{data?.user?.email}</span>
                        </Tooltip>
                    </div>
                </div>

            </td>



            <td className={`p-s10 border-r-[1px] text-center max-w-[120px] min-w-[120px] truncate ${data?.is_seen === 0 ? "font-semibold" : ""} `}>
                {data?.subject ? <Tooltip title={data?.subject}>
                    <div className='truncate'>{data?.subject}</div>
                </Tooltip> : 'NA'}
            </td>

            <td className={`px-s15 py-s10 border-r-[1px] text-center max-w-[200px] min-w-[200px] truncate ${data?.is_seen === 0 ? "font-semibold" : ""} `}>
                {data?.message ? <Tooltip title={data?.message}>
                    <div className='truncate'>{data?.message}</div>
                </Tooltip> : 'NA'}
            </td>

            <td className={`text-center capitalize p-s10 border-r-[1px] max-w-[80px] min-w-[80px] truncate ${data?.is_seen === 0 ? "font-semibold" : ""} `}>
                {data?.user?.role === "sa" ? "super admin" : data?.user?.role === 'private' ? 'Customer' : data?.user?.role}
            </td>

            <td className={`p-s10 border-r-[1px] text-center ${data?.is_seen === 0 ? "font-semibold" : ""} `}>
                {formatDate(data?.created_at)}
            </td>


            {/* <td
                onClick={() => {
                    setSelectedMessageID(data?.id)
                    setMessageUserID(data?.user_id)
                }}
                className='flex justify-center items-center px-5 py-s10'
            >

                <Tooltip title="send a reply">
                    <img onClick={() => setShowReplyMessage(true)} src={ReplyIcon} alt="" className='mr-5 cursor-pointer' />
                </Tooltip>

                <Tooltip title="delete this message">
                    <img onClick={() => setShowDeleteMessage(true)} src={DeleteIcon} alt="" className='cursor-pointer' />
                </Tooltip>
            </td> */}
        </tr>
    )
}
