import { useEffect, useState } from 'react';
import useCustomerStore, { addNewCustomer } from '../../../../App/stores/CustomerStore';
import CommonButton from '../../../../Components/Button/CommonButton';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { CheckEmailValidity, checkValidPassword, FullFormDataValidation, Toastr } from '../../../../Utility/UtilityFunctions';

function AddCustomerModal() {
    const { showAddCustomer, setShowAddCustomer } = useCustomerStore();

    const [addCustomerFormData, setAddCustomerFormData] = useState({
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: 'private'     //customer role is always private
    });


    const handleAddCustomer = async (e) => {
        e.preventDefault();
        if (addCustomerFormData?.password.length < 8 && addCustomerFormData.password_confirmation.length < 8)
            return Toastr('Password must be 8 character long', "warning");

        if (!CheckEmailValidity(addCustomerFormData?.email)) return Toastr('Enter a valid email address!', "warning");
        if (addCustomerFormData?.password !== addCustomerFormData.password_confirmation) return Toastr('Both password fields should be same !', "warning");
        if (checkValidPassword(addCustomerFormData?.password).status === false) return Toastr(checkValidPassword(addCustomerFormData?.password).message, "warning");
        if (FullFormDataValidation(addCustomerFormData)) {
            let addSuccess = await addNewCustomer(addCustomerFormData);
            if (addSuccess) {
                setShowAddCustomer(false);
                setAddCustomerFormData({
                    name: '',
                    email: '',
                    password: '',
                    password_confirmation: '',
                    role: 'private'     //customer role is always private
                });
            }
        }
        else Toastr('Need to fill up all required fields with valid data!', "warning");
    }
    return (
        <>
            <CommonModal
                showModal={showAddCustomer}
                setShowModal={setShowAddCustomer}
                modalTitle="Add Customer"
                mainContent={
                    <>
                        <form onSubmit={handleAddCustomer}>

                            <div className="pt-5"></div>
                            <CommonInput
                                value={addCustomerFormData?.name}
                                required={true}
                                withStar={false}
                                onChange={(e) => setAddCustomerFormData({ ...addCustomerFormData, name: e.target.value })}
                                label={"Name"}
                                min_input={5}
                                placeholder="Enter customer name"
                            />

                            <div className="pt-5"></div>
                            <CommonInput
                                value={addCustomerFormData?.email}
                                onChange={(e) => setAddCustomerFormData({ ...addCustomerFormData, email: e.target.value })}
                                required={true}
                                withStar={false}
                                label={"Email"}
                                type="email"
                                placeholder="Enter customer email"
                            />

                            <div className="pt-5"></div>
                            <CommonInput
                                type='password'
                                togglePasswordBtn={true}
                                value={addCustomerFormData?.password}
                                onChange={(e) => setAddCustomerFormData({ ...addCustomerFormData, password: e.target.value })}
                                required={true}
                                withStar={false}
                                placeholder="Enter customer password"
                                label={"password"}
                            />

                            <div className="pt-5"></div>
                            <CommonInput
                                type='password'
                                togglePasswordBtn={true}
                                value={addCustomerFormData?.password_confirmation}
                                onChange={(e) => setAddCustomerFormData({ ...addCustomerFormData, password_confirmation: e.target.value })}
                                required={true}
                                withStar={false}
                                placeholder="Re-enter customer password"
                                label={"confirm password"}
                            />

                            <div className='flex justify-center mt-s20'><CommonButton type='submit' btnLabel='Save' /></div>

                        </form>
                    </>
                }
            />
        </>
    )
}

export default AddCustomerModal

