import { t } from 'i18next';
const ProductAccordion = ({ productArray }) => {
  return (
    <div className="gs-text-body">
      {productArray.map((item, index) => (
        <div key={index} className="flex justify-between items-center mb-5">
          <div className="text-sm gs-text-icon-color">{t("Product Code")}</div>
          <button className="text-sm gs-text-body text-right font-semibold max-w-[20vw] truncate">
            {item.text ? item.text : <span className="text-gray-400">N/A</span>}
          </button>
        </div>
      ))}
    </div>
  );
};

export default ProductAccordion;
