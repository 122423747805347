/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect } from 'react';
import useCompanyStore from '../../../../App/stores/CompanyStore';
import useCreateRequestStore, { getAdvanceCalculationData, getFastCalculationData, getShiftDetails, useCreateReqPatchStore } from '../../../../App/stores/CreateRequestStore';
import OutlineButton from '../../../../Components/Button/OutlineButton';
import Loading from '../../../../Components/Loading/Loading';
import CommonModal from '../../../../Components/Modal/CommonModal';
import { RequestContext } from '../../../../Context/RequestContext';
import { calculateDistance, formatTime } from '../../../../Utility/UtilityFunctions';
import Stoppage from '../../../Users/Companies/CompanyDetails/CloudRequest/Stoppage';


export default function ShiftSummeryModal({ shiftID, request_id = null }) {
    const { setIsShiftModalOpen, isShiftModalOpen, setIsShiftDetailsLoading, isShiftDetailsLoading, setIsFastCalculate, isFastCalculate, isCalculateLoading } = useCreateReqPatchStore();
    const { companyID } = useCompanyStore();

    const {
        // isShiftModalOpen, setIsShiftModalOpen,
        shiftDetails,
        // setIsShiftDetailsLoading, isShiftDetailsLoading,
        // setIsFastCalculate, isFastCalculate,isCalculateLoading
    } = useCreateRequestStore();

    useEffect(() => {
        console.log('getShiftDetails shiftID', shiftID);
        if (isShiftModalOpen) {
            setIsShiftDetailsLoading(true)
            getShiftDetails(parseInt(shiftID), companyID);
        }
    }, [isShiftModalOpen])

    useEffect(() => {
        setIsFastCalculate(true);
    }, []);


    const month = {
        '01': 'Jan',
        '02': 'Feb',
        '03': 'Mar',
        '04': 'Apr',
        '05': 'May',
        '06': 'Jun',
        '07': 'Jul',
        '08': 'Aug',
        '09': 'Sep',
        '10': 'Oct',
        '11': 'Nov',
        '12': 'Dec',
    }


    return (

        <CommonModal
            showModal={isShiftModalOpen}
            setShowModal={setIsShiftModalOpen}
            modalTitle={t("Shift Summery")}
            scrollModal={false}
            mainContent={
                isShiftDetailsLoading ?
                    <Loading />
                    :
                    <div className={`bg-cWhite rounded-br10 mt-5 ${isCalculateLoading ? 'max-h-auto' : 'max-h-[70vh] overflow-y-auto overflow-x-hidden'} `}>

                        <div className="bg-cListItem px-5 py-5 rounded-br10">
                            {/* shiftDetails basic info */}
                            <BasicInfo shiftDetails={shiftDetails} month={month} request_id={request_id} />

                            <div className='my-5'></div>

                            {/* requests, stops, packages */}
                            <RequestStopsPackages shiftDetails={shiftDetails} />

                            <div className='my-5'></div>

                            {/* comment */}
                            <Comment comment={shiftDetails?.comment} />

                            <div className='my-5'></div>

                            {/* starts in info */}
                            {
                                (shiftDetails?.status !== 'complete' && shiftDetails?.status !== 'ongoing') && <div className='h-50 w-full text-center  flex justify-center  text-cWhite font-fw500 text-fs16 '>
                                    <div className='bg-cPlaceholder px-5 rounded-br5 py-2'>{t("Start In")} {shiftDetails?.starts_in}</div>
                                </div>
                            }

                        </div>

                        <FastAdvanceCalculationButtons shiftID={shiftID} request_id={request_id} />

                        <hr />

                        <div className='my-5'></div>

                        {isCalculateLoading ? <Loading /> : (isFastCalculate ? <FastCalculation /> : <AdvanceCalculation />)}


                    </div>

            } />

    )
}


const BasicInfo = ({ shiftDetails, month, request_id = null }) => {
    const { id } = useContext(RequestContext);

    useEffect(() => {
        getFastCalculationData(request_id ?? id)
        // console.log('id', id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <div className='flex flex-row justify-start items-center w-full text-cListIcon'>
            <div className='h-[50px] w-[50px] rounded-full bg-cLine text-center'>
                <div className=' text-fs20 font-fw600'> {shiftDetails?.start_date?.substring(8, 10)} </div>
                <div className=' text-fs10 font-fw400'>{month[shiftDetails?.start_date?.substring(5, 7)]}</div>
            </div>


            <div className='flex flex-row justify-between items-center w-full'>
                <div className='flex flex-col justify-center items-left ml-2 w-3/4'>
                    <div className='text-fs12 text-cMainBlack font-fw500'>{shiftDetails?.weekday}</div>
                    <div className='text-fs12 text-cMainBlack font-fw500'>{shiftDetails?.car.name}</div>
                </div>

                <div className='flex flex-row justify-end items-center w-1/4 space-x-7'>
                    <div className='flex flex-col justify-center items-end ml-2'>
                        <div className='text-fs12 text-cMainBlack font-fw500'>
                            {shiftDetails?.start_time.substring(0, 5) + ' - ' + shiftDetails?.end_time.substring(0, 5)}</div>
                        <div className='text-fs12 text-cMainBlack font-fw500'>{shiftDetails?.car?.car_license_plate_number}</div>
                    </div>

                </div>
            </div>
        </div>
    )
}

const RequestStopsPackages = ({ shiftDetails }) => {
    return (
        <div className='flex flex-row justify-between'>
            <RSPCard title={t("Request")} total={shiftDetails?.request_count} done={shiftDetails?.request_completed} />
            <RSPCard title={t("Stops")} total={shiftDetails?.stops_count} done={shiftDetails?.stops_completed} />
            <RSPCard title={t("Packages")} total={shiftDetails?.products_count} done={shiftDetails?.products_completed} />
        </div>
    )
}

const RSPCard = ({ title, total, done }) => {
    return (
        <div className='h-[70px] bg-white py-3 px-[60px] flex flex-col justify-center items-center rounded-br5'>
            <div className='text-cIcon text-fs14 font-fw400'>{title}</div>
            <div className='text-cMainBlack text-fs18 font-fw600'>{done + '/' + total}</div>
        </div>
    )
}

const Comment = ({ comment }) => {
    return (
        <div className='flex flex-col items-start text-left'>
            <div className='text-cMainBlack font-fw500 text-fs14'>{t("Comment")}</div>
            <div className='text-cBodyText font-fw400 text-fs14'>{comment ?? 'NA'}</div>
        </div>
    )
}

export const FastAdvanceCalculationButtons = ({ request_id = null, shiftID = null }) => {
    const { id } = useContext(RequestContext);


    const { setIsFastCalculate, isFastCalculate } = useCreateReqPatchStore();
    return (
        <div className="flex flex-row justify-between  py-5">
            <OutlineButton
                className={`${isFastCalculate ? "bg-cPrimary text-cWhite" : ''}`}
                label={t("Fast Calculation")}
                onSubmit={() => {
                    setIsFastCalculate(true);
                    console.log('id, req id', id, request_id);
                    getFastCalculationData(request_id ?? id)
                }}
            />

            <OutlineButton
                className={`${!isFastCalculate ? "bg-cPrimary text-cWhite" : ''}`}
                label={t("Advance Calculation")}
                onSubmit={async () => {
                    setIsFastCalculate(false);
                    await getAdvanceCalculationData(request_id ?? id, shiftID)
                }}
            />
        </div>
    )
}

const calculateTime = (data) => {
    let time = 0;
    let unit = 'Hrs';
    time = data / 3600;
    if (time < 1) {
        time = time * 60;
        time = Math.round(time);
        unit = 'Mins'
    } else {
        time = Math.round(time);
    }

    return {
        time: time ?? 0,
        unit: unit
    }
}


export const FastCalculation = () => {
    const { fastCalculationData } = useCreateReqPatchStore();

    return (
        <div className='flex flex-col justify-start  '>
            <div className='text-cMainBlack font-fw600 text-fs-16 mb-5'>{t("Fast Calculation")}</div>


            <div className='h-[60px] w-full flex flex-row justify-around items-center bg-cLine rounded-br5'>

                <div className='text-center'>
                    <div className='text-fs12 text-cMainBlack font-fw400'>{t("Distance")}</div>
                    <div className='text-fs18 text-cMainBlack font-fw600'> {calculateDistance(fastCalculationData?.distance).distance} {t(calculateDistance(fastCalculationData?.distance).unit)}</div>
                </div>

                <div className='w-[1px] h-1/2 bg-cPlaceholder'></div>

                <div className='text-center'>
                    <div className='text-fs12 text-cMainBlack font-fw400'>{t("Duration")}</div>
                    <div className='text-fs18 text-cMainBlack font-fw600'>{calculateTime(fastCalculationData?.time).time} {t(calculateTime(fastCalculationData?.time).unit)}</div>
                </div>

            </div>
        </div>
    )
}

export const AdvanceCalculation = () => {

    const { advanceCalculationData } = useCreateRequestStore((state) => state)

    return (
        <>
            <div className='flex flex-col justify-start  '>
                <div className='text-cMainBlack font-fw600 text-fs-16 mb-5'>{t("Advance Calculation")}</div>


                <div className='h-[60px] w-full flex flex-row justify-around items-center bg-cLine rounded-br5'>

                    <div className='text-center'>
                        <div className='text-fs12 text-cMainBlack font-fw400'>{t("Distance")}</div>
                        <div className='text-fs18 text-cMainBlack font-fw600'> {calculateDistance(advanceCalculationData?.distance).distance} {t(calculateDistance(advanceCalculationData?.distance).unit)}</div>
                    </div>

                    <div className='w-[1px] h-1/2 bg-cPlaceholder'></div>

                    <div className='text-center'>
                        <div className='text-fs12 text-cMainBlack font-fw400'>{t("Duration")}</div>
                        <div className='text-fs18 text-cMainBlack font-fw600'>{calculateTime(advanceCalculationData?.duration).time} {t(calculateTime(advanceCalculationData?.duration).unit)}</div>
                    </div>

                </div>
            </div>

            <div className="font-bold pt-5">{t("All Stops")}</div>

            <div>
                {advanceCalculationData?.stop_list?.map((stop, index) => {
                    return (
                        <Stoppage
                            key={index}
                            stopNo={stop?.stop_index}
                            stopTitle={stop?.stop_title}
                            distance={calculateDistance(stop?.stop_distance).distance + ' ' + t(calculateDistance(stop?.stop_distance).unit)}
                            status={stop?.stop_details?.type}
                            addressDetails={stop?.stop_address}
                            totalPackages={stop?.stop_products}
                            time={formatTime(stop?.stop_details?.start_time)}
                            is_time_fit={stop?.is_time_fit}
                        />
                    )
                })}
            </div>
        </>
    )
}


