import { useContext, useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { userToggleStatus } from "../../App/stores/CustomerStore";
import CommonSwitch from "../../Components/CommonSwitch/CommonSwitch";
import Dropdown from "../../Components/Dropdown";
import CommonImage from "../../Components/Image/CommonImage";
import {
  iAddress,
  iCvrIcon,
  iDemoUser,
  iEmail,
  iFacebookIcon,
  iLinkedInIcon,
  iPhone,
  iRightArrow,
  iTwitterIcon,
  iWebsiteBlack,
} from "../../Utility/Sources";
import { formatDate } from "../../Utility/UtilityFunctions";
import CommonButton from "../../Components/Button/CommonButton";
import { getExpressToken } from "../../App/stores/GeneralStore";
import useRequestStore from "../../App/stores/RequestStore";
import { useNavigate } from "react-router-dom";
import RatingFiveStar from "./Common/FavoriteCompanies/RatingFiveStar";
import { FavoriteCompanyContext } from "../../Context/FavoriteCompanyContext";
import useFavoriteCompaniesStore from "../../App/stores/FavoriteCompaniesStore";
import { ENVIRONMENT } from "../../App/Utility/const";

export default function CommonDetailsPanel({
  name = "NA",
  image = "",
  badge = "NA",
  email = "NA",
  phone = "NA",
  address = "NA",
  memberSince = "NA",
  company_details,
  is_active = 0,
  id = 0,
  userData = "",
  cvr = "",
  website = "",
  userId = 0,
  totalRating = 0,
  rate = 0,
  totalReq = 0,
  completedReq = 0,
  admin_access = false,
  showSecondaryMenu = false,
  social_media = [],
  secondaryMenuOptions = [
    {
      title: "Option 01",
      action: () => {
        console.log("Option 01");
      },
    },
    {
      title: "Option 02",
      action: () => {
        console.log("Option 02");
      },
    },
    {
      title: "Option 03",
      action: () => {
        console.log("Option 03");
      },
    },
  ],
  customDeactivateFn = () => {},
  optionsArray = [
    {
      title: "Option 01",
      action: () => {
        console.log("Option 01");
      },
    },
    {
      title: "Option 02",
      action: () => {
        console.log("Option 02");
      },
    },
    {
      title: "Option 03",
      action: () => {
        console.log("Option 03");
      },
    },
  ],
}) {
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const { total_request_count, request_count_data } = useRequestStore();
  const { setSelectedFavIndex } = useContext(FavoriteCompanyContext);
  const { setShowReviewModal } = useFavoriteCompaniesStore();
  let status = is_active ? 0 : 1;
  const body = {
    user_id: id,
    is_active: status,
  };

  const handleExpressLogin = async () => {
    const expressTokenFound = await getExpressToken({
      user_id: id,
    });

    if (!expressTokenFound) {
      alert("Express token not found!");
      return;
    }

    if (expressTokenFound) {
      let expressUlr = "";

      if (ENVIRONMENT === "dev") {
        expressUlr =
          process.env.REACT_APP_DEV_URL +
          "express/login/" +
          id +
          "/" +
          expressTokenFound;
      }

      if (ENVIRONMENT === "stg") {
        expressUlr =
          process.env.REACT_APP_STG_URL +
          "express/login/" +
          id +
          "/" +
          expressTokenFound;
      }

      // const expressUlr = 'http://localhost:3000/express/login/' + id + '/' + expressTokenFound;      //for local address

      window.open(expressUlr, "_blank");
    }
  };

  const navigateTo = useNavigate();

  return (
    <div className="flex relative justify-between items-start p-5 bg-white shadow-sm rounded-br10 text-cMainBlack">
      {/* blue: Left */}
      <div className="flex justify-between items-center w-1/2">
        <div className="relative w-full">
          <div className="overflow-hidden rounded-full bg-cLiteGrey w-s100 h-s100">
            <CommonImage
              src={image ? image : iDemoUser}
              alt="user-avatar"
              className="object-cover w-full h-full"
            />
          </div>

          {userData === "company" ? (
            <div
              onClick={() => {
                setSelectedFavIndex(id);
                setShowReviewModal(true);
              }}
              className="mt-s16 flex space-x-2 cursor-pointer"
            >
              <RatingFiveStar rating={totalRating} />
              {/* <div className="text-center flex justify-center item-center">({totalRating})</div> */}
            </div>
          ) : (
            ""
          )}

          <div className="flex flex-col pt-3 pr-s15 md:items-center md:flex-row">
            <div className="font-bold select-none text-fs28 break-all">
              {name}
            </div>
            {/* <div className="text-center capitalize rounded-2xl border w-s150 md:ml-5 text-fs14 text-cSecondary py-s2 border-cSecondary">
              {badge}
            </div> */}
          </div>

          <div className="flex pt-s10 w-fit">
            <CommonSwitch
              checked={is_active}
              onClick={() => {
                if (is_active === 1) {
                  customDeactivateFn();
                } else {
                  userToggleStatus(userId, body, userData);
                }
              }}
            />
            <div className="text-base pl-s10">
              {is_active ? (
                <span className="text-cPrimary">Active</span>
              ) : (
                <span className="text-cRed">Inactive</span>
              )}
            </div>
          </div>

          <div className="pt-5 space-y-s15">
            {userData === "company" && cvr && (
              <BasicInfo
                icon={iCvrIcon}
                text={cvr ? cvr : "NA"}
              />
            )}
            <BasicInfo
              icon={iEmail}
              text={email ? email : "NA"}
            />
            <BasicInfo
              icon={iPhone}
              text={phone ? phone : "NA"}
            />
            {userData !== "driver" && (
              <BasicInfo
                icon={iAddress}
                text={address ? address : "NA"}
              />
            )}
            {userData === "company" && (
              <BasicInfo
                icon={iWebsiteBlack}
                text={website ? website : "NA"}
              />
            )}
          </div>
        </div>

        {/* green: mid */}
        <div className="relative">
          <hr className="relative h-[280px] w-s2  bg-cDetailsBar hidden md:block" />
          {admin_access ? (
            <div className="absolute left-[-150px] -top-1">
              <CommonButton
                onClick={handleExpressLogin}
                btnLabel="Admin Access"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      {/* blue: right */}
      <div className="pl-5 w-1/2">
        {userData === "driver" ? (
          <div>
            {company_details ? (
              <div className="space-y-1">
                <div className="cursor-pointer font-bold text-fs26">
                  Company information
                </div>
                <div
                  onClick={() => {
                    navigateTo(
                      `/users/companies/details/${company_details?.id}`
                    );
                  }}
                  className="cursor-pointer font-fw500 text-fs20"
                >
                  {company_details?.name}
                </div>

                <div className="font-fw400 text-fs12 flex items-center">
                  <img
                    className="mr-[7px]"
                    src={iCvrIcon}
                    alt=""
                  />
                  {company_details?.cvr ? company_details?.cvr : "NA"}
                </div>

                <div className="font-fw400 text-fs12 flex items-center">
                  <img
                    className="mr-s10"
                    src={iEmail}
                    alt=""
                  />
                  {company_details?.email ? company_details?.email : "NA"}
                </div>

                <div className="font-fw400 text-fs12 flex items-center">
                  <img
                    className="mr-s10"
                    src={iPhone}
                    alt=""
                  />
                  {company_details?.phone ? company_details?.phone : "NA"}
                </div>

                <div className="font-fw400 text-fs12 flex items-center">
                  <img
                    className="mr-[11px] ml-s2"
                    src={iAddress}
                    alt=""
                  />
                  {company_details ? (
                    <a
                      className=""
                      target={"_blank"}
                      rel="noreferrer"
                      href={`http://maps.google.com/?q=${company_details?.address}`}
                    >
                      {company_details?.address}
                    </a>
                  ) : (
                    "NA"
                  )}
                </div>

                {/* <div className="font-fw400 text-fs12 flex items-center">
              <img className='mr-s10 ml-s1' src={iWebsiteBlack} alt="" />
              {company_details?.website ? <a target={'_blank'}
              href={company_details?.website?.includes("http") ?
              company_details?.website : `https://${company_details?.website}`} rel='noreferrer'
              className='break-all cursor-pointer'>{company_details?.website}</a> : 'NA'}
            </div> */}
              </div>
            ) : (
              <>
                <div className="cursor-pointer font-bold text-fs26">
                  Company information
                </div>
                <div className="cursor-pointer font-fw500 text-fs14 ">
                  No Company Assign
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="cursor-pointer font-bold text-fs26">Overview</div>
        )}

        <div className="flex justify-start items-center pt-5">
          <GlanceInfo
            title={"Member Since"}
            data={formatDate(Date.parse(memberSince))}
          />
        </div>

        {userData !== "driver" ? (
          <>
            <div className="flex justify-start pt-5">
              <GlanceInfo
                title={"Total Request"}
                data={total_request_count}
              />
            </div>

            <div className="py-5">
              <GlanceInfo
                title={"Completed Request"}
                data={
                  userData === "customer"
                    ? request_count_data?.history
                    : parseInt(request_count_data?.complete) +
                      parseInt(request_count_data.history)
                }
              />
            </div>
          </>
        ) : (
          ""
        )}
        {social_media?.length > 0 && userData === "company" ? (
          <div className="mt-s22 md:mb-0 mb-s25">
            <span className="text-fs24 font-fw600 text-cMainBlack">
              {social_media?.find((item) => item?.link !== "") ? (
                <span>Social Links</span>
              ) : (
                <span>No Social Links</span>
              )}
            </span>
            <div className="flex flex-wrap mt-s13">
              {social_media?.map((item, index) => (
                <div key={index}>
                  {item?.domain.includes("facebook") && item?.link !== "" && (
                    <a
                      target="blank"
                      href={
                        item?.link?.includes("http")
                          ? item?.link
                          : "http://" + item?.link
                      }
                    >
                      <img
                        className="max-w-[30px] min-w-[40px] cursor-pointer mr-s16"
                        src={iFacebookIcon}
                        alt="Facebook icon"
                      />
                    </a>
                  )}

                  {item?.domain.includes("twitter") && item?.link !== "" && (
                    <a
                      target="blank"
                      href={
                        item?.link?.includes("http")
                          ? item?.link
                          : "http://" + item?.link
                      }
                    >
                      <img
                        className="max-w-[30px] min-w-[40px] cursor-pointer mr-s16"
                        src={iTwitterIcon}
                        alt="iTwitter"
                      />
                    </a>
                  )}

                  {item?.domain.includes("linkedin") && item?.link !== "" && (
                    <a
                      target="blank"
                      href={
                        item?.link?.includes("http")
                          ? item?.link
                          : "http://" + item?.link
                      }
                    >
                      <img
                        className="max-w-[30px] min-w-[40px] cursor-pointer"
                        src={iLinkedInIcon}
                        alt="LinkedIn"
                      />
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <Dropdown
        button={
          <div className="absolute top-0 right-0">
            <HiOutlineDotsVertical className="text-2xl font-bold cursor-pointer" />
          </div>
        }
        width={"w-s300"}
        body={
          <div className="relative">
            <DropDownBody
              data={optionsArray}
              showSecondaryMenu={showSecondaryMenu}
              showMoreOptions={showMoreOptions}
              setShowMoreOptions={setShowMoreOptions}
            />

            {/* secondary options to show */}
            {showMoreOptions ? (
              <div
                onMouseEnter={() => setShowMoreOptions(true)}
                onMouseLeave={() => setShowMoreOptions(false)}
                className="bg-white w-s250 drop-shadow-xl rounded-br10 cursor-pointer select-none text-cBodyText absolute bottom-0 -left-[250px]"
              >
                {secondaryMenuOptions?.length > 0
                  ? secondaryMenuOptions.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          if (item?.inActive) return;
                          else item?.action();
                        }}
                        className={`cursor-pointer p-s10 ${
                          item?.inActive
                            ? "bg-gray-100 cursor-not-allowed"
                            : "hover:bg-cMoreLiteGrey"
                        }`}
                      >
                        {item?.title}
                      </div>
                    ))
                  : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        }
      />
    </div>
  );
}

function BasicInfo({ icon, text }) {
  return (
    <div className="flex items-center">
      <img
        src={icon}
        alt="icon"
      />
      <div className="pl-s10">{text}</div>
    </div>
  );
}

function GlanceInfo({ title, data }) {
  return (
    <div>
      <div className="font-fw600">{title}</div>
      <div className="">{data}</div>
    </div>
  );
}

function DropDownBody({
  data,
  showSecondaryMenu,
  showMoreOptions,
  setShowMoreOptions,
}) {
  return (
    <div className="mt-3 bg-white drop-shadow-xl cursor-pointer select-none w-s250 rounded-br10 text-cBodyText">
      {data?.length > 0
        ? data?.map((item, index) => (
            <>
              <div
                key={index}
                onClick={() => {
                  if (item?.inActive) return;
                  else item?.action();
                }}
                className={`p-s10 ${
                  item?.inActive
                    ? "bg-gray-100 cursor-not-allowed"
                    : "hover:bg-cMoreLiteGrey"
                }`}
              >
                {item?.title}
              </div>
            </>
          ))
        : ""}

      {/* more options */}
      {showSecondaryMenu ? (
        <div
          key={9999}
          onClick={() => setShowMoreOptions(!showMoreOptions)}
          onMouseEnter={() => setShowMoreOptions(true)}
          onMouseLeave={() => setShowMoreOptions(false)}
          className="flex justify-between w-full p-s10 hover:bg-cMoreLiteGrey"
        >
          <div>Manage Account </div>
          <div className="">
            <img
              src={iRightArrow}
              alt=""
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
