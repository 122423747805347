import React from 'react'
import { useTranslation } from 'react-i18next';
import { formatTime } from '../../../../../../Utility/UtilityFunctions';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import useShiftStore from '../../../../../../App/stores/ShiftStore';
import CommonViewComponent from '../../../../../../Components/CommonViewComponent';
import useGeneralStore from '../../../../../../App/stores/GeneralStore';

const CustomStopDetails = () => {
    const { t } = useTranslation();
    const { showCustomStopModal, setShowCustomStopModal, customStopModalData } = useShiftStore();
    const { setImageViewerModalUrl,setShowImageViewerModal } = useGeneralStore();
    const defineStatus = (status) => {
        if (status === 'init') return 'Not Started';
        if (status === 'hold') return 'On Hold';
        if (status === 'not_delivered') return 'Not Delivered';
        else return status;
    }

    return (
        <CommonModal
            showModal={showCustomStopModal}
            setShowModal={setShowCustomStopModal}
            modalTitle={t('Custom Stop Details')}
            mainContent={
                <div
                    onClick={() => { console.log('customStopModalData: ', customStopModalData); }}
                    className='pt-5 space-y-5'>
                    <CommonViewComponent capitalizedData={true} labelText={t('status')}
                        value={defineStatus(customStopModalData?.status) ?? "NA"} />
                    <CommonViewComponent capitalizedData={true} labelText={t('stop type')} value={'Custom'} />
                    <CommonViewComponent capitalizedData={false} labelText={t('title')} value={customStopModalData?.title ?? "NA"} />
                    <CommonViewComponent capitalizedData={false} labelText={t('Time Range')} value={(formatTime(customStopModalData?.start_time) ?? "--") + ' - ' + formatTime(customStopModalData?.end_time) ?? "--"} />
                    <CommonViewComponent capitalizedData={false} labelText={t('comment')} value={customStopModalData?.comment ?? "NA"} />
                    {customStopModalData?.attachment ?
                        <div>
                            <div className={`capitalize text-[#939699]`}>Attachment</div>
                            <div className='text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer'
                                onClick={() => {
                                    setImageViewerModalUrl(customStopModalData?.attachment);
                                    setShowImageViewerModal(true);
                                }}>
                                {t('Attachment')}
                            </div>
                        </div>
                        : ''
                    }

                    {/* <RequestDetailsTextTitle title={t(`Custom Stop Overview`)} className={'text-fs16 font-fw500'} /> */}

                    <CommonViewComponent
                        labelText={t('Driver Comment')}
                        value={customStopModalData?.driver_comment ?? 'NA'}
                        className='my-[20px]'
                    />

                    <div className='my-s20'>

                        {
                            customStopModalData?.driver_attachment &&
                            <div>
                                <div className={`capitalize text-[#939699]`}>Driver Attachment</div>
                                <div className='text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer'
                                    onClick={() => {
                                        setImageViewerModalUrl(customStopModalData?.driver_attachment);
                                        setShowImageViewerModal(true);
                                    }}>
                                    {t('Attachment')}
                                </div>
                            </div>
                        }

                    </div>

                    <div className=''>
                        {
                            customStopModalData?.driver_signature &&
                            <div>
                                <div className={`capitalize text-[#939699]`}>Driver Signature</div>
                                <div className='text-fs14 font-fw400 text-[#2257AA] underline cursor-pointer'
                                    onClick={() => {
                                        setImageViewerModalUrl(customStopModalData?.driver_signature);
                                        setShowImageViewerModal(true);
                                    }}>
                                    {t('Signature')}
                                </div>
                            </div>
                        }

                    </div>
                </div>
            }
        />
    )
}

export default CustomStopDetails