import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CommonImage from '../../../Components/Image/CommonImage';
import useRequestStore from '../../../App/stores/RequestStore';
import { k_data_set } from '../../../App/Utility/const';

const CustomerTableRow = ({ data }) => {

  const navigateTo = useNavigate();

  const { setRequestActiveSection,setRequestDetails,setRequestSearchKey } = useRequestStore();

  return (
    <tr onClick={() => {
      setRequestActiveSection(k_data_set.saved);
      setRequestDetails({});
      setRequestSearchKey("");
      navigateTo("/users/customers/details/" + data?.id);
    }}
      className={`border-b-[1px] border-collapse  cursor-pointer hover:bg-cGridView select-none border-cNmSelect "font-semibold"`} >

      <td className='border-r-[1px] p-s10 text-left max-w-[180px] min-w-[180px] truncate'>
        <div className='flex items-center'>
          <div className='mx-s5 max-w-[40px] min-w-[40px]'>
            <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.image} alt="" />
          </div>
          {data?.name ? <Tooltip title={data?.name}><div className='capitalize items-center pl-s10 truncate'>{data?.name}</div></Tooltip> : 'NA'}
        </div>
      </td>

      <td className='p-s10 border-r-[1px] text-center truncate max-w-[200px] min-w-[200px]'>
        {data?.email ? <Tooltip title={data?.email}><div className='truncate' >{data?.email}</div></Tooltip> : 'NA'}
      </td>

      <td className='p-s10 border-r-[1px] text-center truncate max-w-[130px] min-w-[130px]'>
        {data?.phone ? <Tooltip title={data?.phone}><div className='truncate'>{data?.phone}</div></Tooltip> : 'NA'}
      </td>

      <td className={` truncate max-w-[90px] min-w-[80px] p-s10 border-r-[1px] text-center text-fs14 font-fw600 ${data?.is_active ? "text-cSecondary" : "text-cRed"}`}>
        {data?.is_active ?
          "Active"
          :
          "Inactive"
        }
      </td>
    </tr >
  )
}


export default CustomerTableRow;