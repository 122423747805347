import useContactStore, { toggleReadMessage, toggleReadWebMessage } from '../../../App/stores/ContactStore';
import { formatDate } from '../../../Utility/UtilityFunctions';
import { Tooltip } from '@mui/material';
import CommonImage from '../../../Components/Image/CommonImage';

export default function WebMessageItem({ data }) {

    const { setSelectedMessageID, setWebMessageDetails, setMessageUserID, webPaginationUrl } = useContactStore();

    // console.log("data?.is_seen",data?.is_seen)

    return (
        <tr
            onClick={async () => {
                if (data?.is_seen === 0) await toggleReadWebMessage(data?.id, webPaginationUrl);
                setWebMessageDetails(true);
                setSelectedMessageID(data?.id);
                setMessageUserID(data?.user_id);
            }}
            className={`border-collapse cursor-pointer border-b-[1px] border-cNmSelect hover:bg-cGridView overflow-hidden`}
        >
            <td className={`max-w-[200px] min-w-[200px] truncate
              items-left border-r-[1px] text-left p-s10 ${data?.is_seen === 1 ? "font-normal" : "font-semibold"}`}>
                <div className='flex items-center'>
                    <Tooltip title={data?.is_seen === 0 ? "Not Seen" : data?.reply === null ? "Not Replied" : ""} ><div className={` rounded-full w-[10px] h-[10px] ${data?.is_seen === 0 ? "bg-cLink" : data?.reply === null ? "bg-cIcon" : "bg-white"} `}></div></Tooltip>
                    <div className='max-w-[40px] min-w-[40px]'>
                        <CommonImage
                            src={data?.image}
                            alt="" className='w-[40px] h-[40px] rounded-full object-cover'
                        /></div>

                    <div className='flex flex-col pl-s10 truncate'>
                        <Tooltip title={data?.name}>
                            <span className='truncate'>{data?.name}</span>
                        </Tooltip>

                        <Tooltip title={data?.email}>
                            <span className='truncate'>{data?.email}</span>
                        </Tooltip>
                    </div>
                </div>
            </td>

            <td className={`p-s10 border-r-[1px] text-center max-w-[120px] min-w-[120px] truncate ${data?.is_seen === 0 ? "font-semibold" : ""} `}>
                {data?.subject ? <Tooltip title={data?.subject}>
                    <div className='truncate'>{data?.subject}</div>
                </Tooltip> : 'NA'}
            </td>

            <td className={`px-s15 py-s10 border-r-[1px] text-center max-w-[200px] min-w-[200px] truncate ${data?.is_seen === 0 ? "font-semibold" : ""} `}>
                {data?.message ? <Tooltip title={data?.message}>
                    <div className='truncate'>{data?.message}</div>
                </Tooltip> : 'NA'}
            </td>


            <td className={`p-s10 border-r-[1px] text-center ${data?.is_seen === 0 ? "font-semibold" : ""} `}>
                {formatDate(data?.created_at)}
            </td>
        </tr>
    )
}
