import CommonImage from "../../../../../../Components/Image/CommonImage";
import { iDemoUser } from "../../../../../../Utility/Sources";


export default function DriverInfo({ isShiftDetailsData }) {
  return (
    <div className='mb-s10'>
      <div className="flex flex-row justify-start mb-s10">
        <CommonImage className='h-[50px] w-[50px] rounded-full mr-5' src={isShiftDetailsData?.driver_user?.image} dummyImage={iDemoUser} alt="" srcSet="" />
        <div className="flex flex-col items-start justify-center space-y-[2px]">
          <div className='text-cMainBlack text-fs14 font-fw600'>{isShiftDetailsData?.driver_user?.name}</div>
          <div className='text-cBodyText text-fs14 font-fw400'>{isShiftDetailsData?.driver_user?.phone_from_driver ?? 'NA'}</div>
        </div>
      </div>
      <hr />
    </div>
  )
}
