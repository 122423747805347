import create from "zustand";
import { t } from "i18next";
import axios from "axios";
import Pusher from 'pusher-js';
import { chatCounterUrl, kuChatList, kuDeleteMessage, kuGetMessageList, kuSendMessage, kuUserList } from "../../Utility/url";
import useGeneralStore from "./GeneralStore";
import { Toastr } from "../../Utility/UtilityFunctions";
import { chatCluster, chatKey } from "../Utility/const";

const { setLoading, setIsLoadingSearch } = useGeneralStore.getState()

const useChatStore = create((set) => ({

    showAddChatModal: false,
    setShowAddChatModal: (value) => set({ showAddChatModal: value }),

    selectChat: {},
    setSelectChat: (value) => set({ selectChat: value }),

    chat_list: [],
    setAddChatList: (value) => set({ chat_list: value }),

    current_role: "",
    setCurrentRole: (value) => set({ current_role: value }),

    textFieldCount: 40,
    setTextFieldCount: (value) => set({ textFieldCount: value }),

    message_array: [],
    setAddMessageArray: (value) => set({ message_array: value }),

    add_chat_user_list: [],
    setAddChatUserList: (value) => set({ add_chat_user_list: value }),

    chat_search: "",
    setChatSearch: (value) => set({ chat_search: value }),

    user_search: "",
    setUserSearch: (value) => set({ user_search: value }),

    message_notification: false,
    setMessageNotification: (value) => set({ message_notification: value }),

    notification_info: false,
    setNotificationInfo: (value) => set({ notification_info: value }),

    message_scroll_bottom: false,
    setMessage_scroll_bottom: (value) => set({ message_scroll_bottom: value }),

    total_unread_message:0,
    setTotalUnreadMessage: (value) => set({ total_unread_message: value }),

    firebase_new_message:false,
    setFirebaseNewMessage: (value) => set({ firebase_new_message: value }),
    
    showDeleteChatModal: false,
    setShowDeleChatModal: (value) => set({ showDeleteChatModal: value }),

}));

export default useChatStore;


// chat list
export const getChatList = async (search = "", is1stIndexSelect = false, isLoading = true, pagination = false) => {

    const { setAddChatList, setSelectChat, chat_list } = useChatStore.getState();

    try {
        if (search === "") { if (isLoading) setLoading(true); } else { setIsLoadingSearch(true) }
        let body = {};
        if (pagination) { body = { search: search, page: chat_list?.current_page + 1 } }
        else { body = { search: search } }

        const res = await axios.get(kuChatList, { params: body });
        console.log("getChatList res.data:::: ", res?.data);

        if (res?.data?.success) {
            if (!pagination) setAddChatList(res?.data?.data)
            if (is1stIndexSelect) setSelectChat(res?.data?.data?.data[0])
            if (search === "") { if (isLoading) setLoading(false); } else { setIsLoadingSearch(false) }
            return res?.data;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            if (search === "") { if (isLoading) setLoading(false); } else { setIsLoadingSearch(false) }
            return res?.data;
        }
    } catch (error) {
        console.log("getChatList: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        if (search === "") { if (isLoading) setLoading(false); } else { setIsLoadingSearch(false) }
        return {
            success: false
        };
    }
};

//user list
export const getChatUserList = async (body) => {

    const search = body.search
    const pagination = body.pagination
    const isLoading = body.isLoading

    const { add_chat_user_list, setAddChatUserList } = useChatStore.getState();

    try {
        if (!search) { if (isLoading) setLoading(true); } else { setIsLoadingSearch(true) }
        let body = {};
        if (pagination) { body = { search: search, page: add_chat_user_list?.current_page + 1,take:10 } }
        else { body = { search: search,take:10 } }

        const res = await axios.get(kuUserList, { params: body });
        console.log("getChatUserList res.data:::: ", res?.data);

        if (res?.data?.success) {
            if (!pagination) setAddChatUserList(res?.data?.data)
            setLoading(false);
            setIsLoadingSearch(false);
            return res?.data;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            setLoading(false); 
            setIsLoadingSearch(false);
            return res?.data;
        }
    } catch (error) {
        console.log("getChatUserList: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        setLoading(false); 
        setIsLoadingSearch(false);
        return {
            success: false
        };
    }
};

// message list
export const getMessageList = async (body, isLoading = true) => {
   
    const { setAddMessageArray } = useChatStore.getState();

    try {
        if (isLoading) setLoading(true);
        const res = await axios.get(kuGetMessageList, { params: body });
        console.log("getMessageList res.data:::: ", res?.data);

        if (res?.data?.success) {
            setAddMessageArray({
                current_page: res?.data?.data?.current_page,
                last_page: res?.data?.data?.last_page,
                data: res?.data?.data?.data?.slice().reverse(),
                total: res?.data?.data?.total
            });
            setLoading(false);
            return res?.data;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log("getMessageList: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        setLoading(false);
        return false;
    }
};

//send message
export const sendMessage = async (body) => {
    const { selectChat } = useChatStore.getState();
    try {

        const res = await axios.post(kuSendMessage, body);
        console.log("sendMessage res.data:::: ", res?.data);

        if (res?.data?.success) {
            
            await newUserSubscribe(selectChat?.channel_name + "-" + res?.data[0]?.chat_id);
            return res?.data;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            return res?.data;
        }
    } catch (error) {
        console.log("sendMessage: ", error);
        // Toastr({ message: t("An error occurred!"), type: "error" });
        return false;
    }
};

//delete message
export const deleteMessage = async (id) => {
    const { setAddMessageArray, chat_search } = useChatStore.getState();
    try {
        setLoading(true);
        const res = await axios.post(kuDeleteMessage, { "chat_id": id });
        console.log("deleteMessage res.data:::: ", res?.data);

        if (res?.data?.success) {
            Toastr({ message: res?.data?.message, type: "success" });
            setAddMessageArray([]);
            getChatList(chat_search);
            setLoading(false);
            return true;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            setLoading(false);
            return false;
        }
    } catch (error) {
        console.log("deleteMessage: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        setLoading(false);
        return false;
    }
};

//chat counter
export const getChatCounter = async () => {

    const { setTotalUnreadMessage } = useChatStore.getState();
    try {
        const res = await axios.get(chatCounterUrl);
        // console.log("getChatCounter res.data:::: ", res?.data);

        if (res?.data?.success) {
            setTotalUnreadMessage(res?.data?.data?.total_unread_message);
            return true;
        } else {
            Toastr({ message: res?.data?.message, type: "error" });
            return false;
        }
    } catch (error) {
        console.log("getChatCounter: ", error);
        Toastr({ message: t("An error occurred!"), type: "error" });
        return false;
    }
};


const newUserSubscribe = (channel_name, id) => {
    const pusher = new Pusher(chatKey, { cluster: chatCluster });
    const channel = pusher.subscribe(channel_name?.channel_name + "-" + id);
    return () => {
        channel.unsubscribe();
    };
}
