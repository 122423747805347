/* eslint-disable react-hooks/exhaustive-deps */
import { FaAngleDoubleLeft } from "react-icons/fa";
// Icons
import { Link } from "react-router-dom";
// Images & Icons
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationNormal, ApplicationSelected, BannerNormal, BannerSelected, ChatNormal, ChatSelected, CompanyNormal, CompanySelected, CustomerNormal, CustomerSelected, DashboardNormal, DashboardSelected, DriverNormal, DriverSelected, LicenseNormal, LicenseSelected, PackageNormal, PackageSelected, SettingsNormal, SettingsSelected, UsersNormal, UsersSelected } from "../../App/Utility/ImageImports";
import useCompanyStore from "../../App/stores/CompanyStore";
import useCustomerStore from "../../App/stores/CustomerStore";
import useDriverStore from "../../App/stores/DriverStore";
import useLicenseStore from "../../App/stores/LicenseStore";
import useChatStore, { getChatCounter } from "../../App/stores/chatStore";
import { LOGO } from "../../Utility/Sources";
import Section from "./Section";
import SidebarAccordion from "./SidebarAccordion";
import SubSection from "./SubSection";

const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {

  const { setApplicationSearchValue, setApplicationPageUrl, setLicenseTakeAmount } = useLicenseStore();

  const { setCompanySearchValue, setCompanyPageUrl, setCompanyTakeAmount } = useCompanyStore();

  const { setCustomerSearchValue, setCustomerPageUrl, setCustomerTakeAmount } = useCustomerStore();

  const { setDriverSearchValue, setDriverPageUrl, setDriverTakeAmount } = useDriverStore();

  const { total_unread_message, setMessageNotification, message_notification, setChatSearch, setSelectChat } = useChatStore();


  // const { active_route } = useContext(AuthContext);

  let active_route = window.location.pathname;

  useEffect(() => {
    getChatCounter()
  }, [])


  useEffect(() => {
    getChatCounter();
    setMessageNotification(false);
  }, [message_notification])

  const { t } = useTranslation()

  // !======================================= Checking Sections ====================================
  // ! Check Active Route, Sections & Sub Sections
  let active_section = "";
  let active_sub_section = "";

  // Sub Sections
  if (active_route.includes("request/saved")) active_sub_section = "saved";
  if (active_route.includes("request/in-bidding")) active_sub_section = "in_bidding";
  if (active_route.includes("request/awarded")) active_sub_section = "awarded";
  if (active_route.includes("request/ongoing")) active_sub_section = "ongoing";
  if (active_route.includes("request/completed")) active_sub_section = "completed";
  if (active_route.includes("request/history")) active_sub_section = "history";

  if (active_route.includes("license/application")) active_sub_section = "application";
  if (active_route.includes("license/package")) active_sub_section = "package";

  if (active_route.includes("monkey")) active_sub_section = "monkey";
  if (active_route.includes("pirate")) active_sub_section = "pirate";
  if (active_route.includes("ninja")) active_sub_section = "ninja";
  if (active_route.includes("random-page")) active_sub_section = "random_page";

  // Sections
  if (active_route.includes("/") && !active_route.includes('/profile') && !window.location.pathname.includes('/notifications')) active_section = "dashboard";
  if (active_route.includes("request/create")) active_section = "create_request";
  if (active_route.includes("favorite/companies")) active_section = "favorite_companies";
  if (active_route.includes("favorite/address")) active_section = "favorite_address";
  if (active_route.includes("settings")) active_section = "settings";
  if (active_route.includes("license")) active_section = "license";
  if (active_route.includes("banner")) active_section = "banner";
  if (active_route.includes("chat")) active_section = "chat";
  if (active_route.includes("transportation-types")) active_section = "transportationTypes";

  if (active_route.includes("contact-us")) active_section = "contact-us";
  if (active_route.includes("contact-us/web")) active_section = "contact-us";

  if (active_route.includes("contact-us")) active_sub_section = "contact-app";
  if (active_route.includes("contact-us/web")) active_sub_section = "contact-web";

  //users
  if (active_route.includes('users')) active_section = 'users';
  if (active_route.includes('users/customers')) active_sub_section = 'users_customers';
  if (active_route.includes('users/drivers')) active_sub_section = 'users_drivers';
  if (active_route.includes('users/companies')) active_sub_section = 'users_companies';

  if (["saved", "in_bidding", "awarded", "ongoing", "completed", "history",].includes(active_sub_section))
    active_section = "request";

  if (["users_customers", "users_drivers", "users_companies"].includes(active_sub_section)) active_section = "users";

  if (["application", "package"].includes(active_sub_section)) active_section = "license";

  if (["monkey", "pirate", "ninja", "random_page"].includes(active_sub_section))
    active_section = "hello_world";

  // !===============================================================================================

  return (
    // ! To Add auto hide add these 2 lines in bottom tag "-translate-x-full md:translate-x-0"
    <aside
      className={`${isSidebarOpen ? "w-64" : "w-[100px]"} fixed h-full sidebar overflow-hidden md:shadow z-50 transform transition-all duration-150 ease-in bg-white`} >
      <div className="sidebar-header flex items-center justify-center py-4 h-[4.5rem]">
        <Link to="/" className="inline-flex flex-row items-center">
          <div className={`${isSidebarOpen ? "h-10" : "h-6 px-4"}`}>
            <img src={LOGO} alt="" className="w-36" />
          </div>
        </Link>
      </div>

      <div>
        <div className="flex justify-end mb-5">
          <div onClick={() => setIsSidebarOpen(!isSidebarOpen)} className="inline p-1 bg-gray-300 cursor-pointer">
            <FaAngleDoubleLeft
              className={`text-gray-700 transition-all duration-500 ${isSidebarOpen ? "" : "rotate-180"
                }`}
            />
          </div>
        </div>

        <Section
          isSidebarOpen={isSidebarOpen}
          url="/"
          icon={active_section === "dashboard" ? DashboardSelected : DashboardNormal}
          active_image={DashboardSelected}
          title={t("Dashboard")}
          is_image={true}
          is_active={active_section === "dashboard"}
        />

        <SidebarAccordion
          isSidebarOpen={isSidebarOpen}
          isInitOpen={active_section === "users"}
          icon={active_sub_section === "users_customers" || active_sub_section === "users_companies" || active_sub_section === "users_drivers" ? UsersSelected : UsersNormal}
          is_active={active_sub_section === "users_customers" || active_sub_section === "users_companies" || active_sub_section === "users_drivers" ? true : false}
          active_image={UsersSelected}
          title={t("Users")}
          body={
            <>
              <SubSection
                onClick={() => {
                  if (active_route !== '/users/customers') {
                    setCustomerSearchValue("");
                    setCustomerPageUrl("");
                    setCustomerTakeAmount(10)
                  }
                }}  //it will be updated later
                isSidebarOpen={isSidebarOpen}
                url="/users/customers"
                icon={active_sub_section === "users_customers" ? CustomerSelected : CustomerNormal}
                active_image={CustomerSelected}
                title={t("Customers")}
                is_active={active_sub_section === "users_customers"}
              />

              <SubSection
                onClick={() => {
                  if (active_route !== '/users/companies') {
                    setCompanySearchValue("");
                    setCompanyPageUrl("");
                    setCompanyTakeAmount(10)
                  }
                }}   //it will be updated later
                isSidebarOpen={isSidebarOpen}
                url="/users/companies"
                icon={active_sub_section === "users_companies" ? CompanySelected : CompanyNormal}
                active_image={CompanySelected}
                title={t("Companies")}
                is_active={active_sub_section === "users_companies"}
              />

              <SubSection
                onClick={() => {
                  if (active_route !== '/users/drivers') {
                    setDriverSearchValue("");
                    setDriverPageUrl("");
                    setDriverTakeAmount(10);
                  }

                }}   //it will be updated later
                isSidebarOpen={isSidebarOpen}
                url="/users/drivers"
                icon={active_sub_section === "users_drivers" ? DriverSelected : DriverNormal}
                active_image={DriverSelected}
                title={t("Drivers")}
                is_active={active_sub_section === "users_drivers"}
              />
            </>
          }
        />



        <Section
          isSidebarOpen={isSidebarOpen}
          url="/banner"
          icon={active_section === "banner" ? BannerSelected : BannerNormal}
          active_image={BannerSelected}
          title={t("Banner")}
          is_image={true}
          is_active={active_section === "banner"}
        />



        <SidebarAccordion
          isSidebarOpen={isSidebarOpen}
          isInitOpen={active_section === "license"}
          icon={active_sub_section === "application" || active_sub_section === "package" ? LicenseSelected : LicenseNormal}
          active_image={LicenseSelected}
          title={t("License")}
          is_active={active_sub_section === "application" || active_sub_section === "package" ? true : false}
          body={
            <>
              <SubSection
                onClick={async () => {
                  await setApplicationPageUrl("")
                  await setApplicationSearchValue("")
                  await setLicenseTakeAmount(10)
                }}
                isSidebarOpen={isSidebarOpen}
                url="/license/application"
                icon={active_sub_section === "application" ? ApplicationSelected : ApplicationNormal}
                active_image={ApplicationSelected}
                title={t("Application")}
                is_active={active_sub_section === "application"}
              />

              <SubSection
                isSidebarOpen={isSidebarOpen}
                url="/license/package"
                icon={active_sub_section === "package" ? PackageSelected : PackageNormal}
                active_image={PackageSelected}
                title={t("Package")}
                is_active={active_sub_section === "package"}
              />
            </>
          }
        />

        <Section
          onClick={() => {
            setChatSearch("");
            setSelectChat({})
          }}
          total_unread_message={total_unread_message}
          isSidebarOpen={isSidebarOpen}
          url="/chat"
          icon={active_section === "chat" ? ChatSelected : ChatNormal}
          active_image={ChatSelected}
          title={t("Chat")}
          is_image={true}
          is_active={active_section === "chat"}
        />

        <Section
          onClick={() => {
            setChatSearch("");
            setSelectChat({})
          }}
          total_unread_message={total_unread_message}
          isSidebarOpen={isSidebarOpen}
          url="/transportation-types"
          icon={active_section === "transportationTypes" ? PackageSelected : PackageNormal}
          active_image={PackageSelected}
          title={t("Transportation types")}
          is_image={true}
          is_active={active_section === "transportationTypes"}
        />



        {/* <SidebarAccordion
          isSidebarOpen={isSidebarOpen}
          isInitOpen={active_section === "contact-us"}
          icon={active_section === "contact-us" ? ContactSelected : ContactNormal}
          title={t("Contact us")}
          is_active={active_sub_section === "contact-web" || active_sub_section === "contact-app" ? true : false}
          active_image={ContactSelected}
          body={
            <>
              <SubSection
                onClick={async () => {
                  if (active_route !== '/contact-us') { contactFilterClear() }
                }}
                isSidebarOpen={isSidebarOpen}
                url="/contact-us"
                icon={active_sub_section === "contact-app" ? iAppSelected : iAppNormal}
                title={t("App")}
                is_active={active_sub_section === "contact-app"}
                active_image={iAppSelected}
              />

              <SubSection
                onClick={async () => {
                  if (active_route !== '/contact-us/WEB') { webContactFilterClear(); setWebSearchValue("") }
                }}
                isSidebarOpen={isSidebarOpen}
                url="/contact-us/web"
                icon={active_sub_section === "contact-web" ? iWebSelected : iWebNormal}
                title={t("Web")}
                is_active={active_sub_section === "contact-web"}
                active_image={iWebSelected}
              />
            </>
          }
        /> */}

        {/* <div onClick={() => {
          if (active_route !== '/contact-us') { contactFilterClear() }
        }}>
          <Section
            isSidebarOpen={isSidebarOpen}
            url="/contact-us"
            icon={active_section === "contact-us" ? ContactSelected : ContactNormal}
            active_image={ContactSelected}
            title={t("Contact us")}
            is_active={active_section === "contact-us"}
            is_image={true}
          />
        </div> */}

        <Section
          isSidebarOpen={isSidebarOpen}
          url="/settings"
          icon={active_section === "settings" ? SettingsSelected : SettingsNormal}
          active_image={SettingsSelected}
          title={t("Settings")}
          is_active={active_section === "settings"}
          is_image={true}
        />

      </div>
    </aside>
  );
};

export default Sidebar;
