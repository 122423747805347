import { useContext, useEffect, useState } from "react";
import { TrackOnlineContext } from "../../../../../../Context/TrackOnlineContext";
import { iRightArrow } from "../../../../../../Utility/Sources";

const StopItem = ({ item, index }) => {
  // to check if item is selected
  const { selectedStop, setSelectedStop } = useContext(TrackOnlineContext);

  const [statusText, setStatusText] = useState("");

  useEffect(() => {
    switch (item.status) {
      case "init":
        setStatusText("");
        break;
      case "delivered":
        setStatusText("complete");
        break;
      case "not_delivered":
        setStatusText("not delivered");
        break;
      case "ongoing":
        setStatusText("on going");
        break;
      case "hold":
        setStatusText("on hold");
        break;
      default:
        setStatusText(item.status);
        break;
    }
  }, [item]);

  return (
    <div className="flex item-center justify-between w-full">
      <div
        className={`w-[5px] mt-[10px] ${selectedStop === item.id ? "bg-cListIcon" : "bg-[#ffffff]"
          } mr-[10px]`}
      ></div>

      <div
        onClick={() => {
          setSelectedStop(item.id);
        }}
        className="flex justify-between items-center w-full bg-cListItem p-[10px] rounded-[5px] cursor-pointer select-none"
      >
        <div>
          <div className="gs-text-main-black text-sm font-semibold max-w-[18vw] truncate">
            {item.title ? item.title : "NA"}
          </div>
          <div className="gs-text-body text-xs py-[5px] max-w-[20vw] truncate">
            {item?.address}
          </div>
          <div className="gs-text-body text-xs flex max-w-full truncate">
            <div>{"Stop " + index}</div>
            <div className="px-[10px]">
              {item && item ? item?.products.length : "0"}
              {"  "}Packages
            </div>
            <div className="capitalize">{statusText}</div>
          </div>
        </div>
        <div>
          <img src={iRightArrow
          } alt="right-arrow-icon" />
        </div>
      </div>
    </div>
  );
};

export default StopItem;
