import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { dateDifference, formatDate, getFormatedStringFromDays } from "../../Utility/UtilityFunctions";
import CommonImage from "../Image/CommonImage";

const LicenseTableRow = ({ data, index }) => {
  const navigateTo = useNavigate();

  // console.log("data",data)

  return (
    <tr
      onClick={() => {
        navigateTo("/license/application/" + data?.id + "/details");
      }}
      className={`border-collapse cursor-pointer border-b-[1px] border-cNmSelect hover:bg-cGridView`} >
      <th className=' border-r-[1px] text-center p-s10 m-2'>
        <span className='mr-3 dm:mr3 md:mr-0'>{index + 1}</span>
      </th>

      <td className='border-r-[1px] p-s10 text-left max-w-[180px] min-w-[180px] truncate'>
        <div className='flex items-center'>
          <div className='mx-s5 max-w-[40px] min-w-[40px]'>
            <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.image} alt="" />
          </div>
          {data?.name ? <Tooltip title={data?.name}><div className='items-center truncate pl-s10'>{data?.name}</div></Tooltip> : 'NA'}
        </div>
      </td>

      <td className='py-s10 border-r-[1px] max-w-[120px] min-w-[120px] px-s10 truncate text-center text-fs14 text-cTextBody'>
        {data?.license?.number}
      </td>

      <td className='py-s10 border-r-[1px] max-w-[180px] min-w-[180px] px-s10 truncate text-center text-fs14 text-cTextBody'>
        {
          data?.company?.name ?
            <Tooltip title={data?.company?.name}><div className='truncate'>{data?.company?.name}</div></Tooltip> : "NA"}
      </td>

      <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
      {(data?.license_start && data?.license_end) ?`${formatDate(data?.license_start)} - ${formatDate(data?.license_end)}` : "NA"}
        {/* {calculateDate(data?.license_end,data?.license_start)} Days */}
        {/* {getFormatedStringFromDays(dateDifference(data?.license_start, data?.license_end, data))} */}
      </td>

      {/* <td className='py-s10 border-r-[1px] pl-s10 text-fs14  text-center  text-cTextBody'>
        {formatDate(data?.license_start)}
      </td> */}

      <td className='py-s10 border-r-[1px] px-s10 text-center'>
        {formatDate(data?.updated_at)}
      </td>

    </tr>
  )
}


export default LicenseTableRow;