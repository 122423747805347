import React, { useState } from 'react'
import CommonButton from '../../../Components/Button/CommonButton';
import { useTranslation } from "react-i18next";
import Input01 from '../../../Components/Input/Input01';
import { submitChangePassword } from '../../../App/stores/SettingsStore';
import CommonInput from '../../../Components/Input/CommonInput';


function ChangePassword({ cpCanSubmit }) {
    const { t } = useTranslation();

    const [cpForm, setCpForm] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });
    const handleCpChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setCpForm({ ...cpForm, [name]: value });
    };

    return (
        <form onSubmit={(e) => e.preventDefault()} >
            <div className="text-2xl font-bold mb-5">{t("Change Password")}</div>

            <CommonInput
                withStar={false}
                label={t("Current Password")}
                name="currentPassword"
                placeholder={t("Type Current Password")}
                value={cpForm?.currentPassword}
                onChange={handleCpChange}
                type="password"
                togglePasswordBtn={true}
                required={true}
            />
            <div className="py-5">

                <CommonInput
                    withStar={false}
                    label={t("New Password")}
                    name="newPassword"
                    placeholder={t("Type New Password")}
                    value={cpForm?.newPassword}
                    onChange={handleCpChange}
                    type="password"
                    togglePasswordBtn={true}
                    required={true}
                />

            </div>
            <CommonInput
                withStar={false}
                label={t("Confirm New Password")}
                name="confirmNewPassword"
                placeholder={t("Type New Password")}
                value={cpForm?.confirmNewPassword}
                onChange={handleCpChange}
                type="password"
                togglePasswordBtn={true}
                required={true}
            />

            <div className="flex flex-row justify-center pt-5">
                <CommonButton
                    type='submit'
                    btnLabel={t("Change Password")}
                    onClick={async () => {
                        const is_success = await submitChangePassword(cpForm);
                        if (is_success)
                            setCpForm({
                                currentPassword: "",
                                newPassword: "",
                                confirmNewPassword: "",
                            });
                    }}
                    colorType={cpForm?.currentPassword && cpForm?.newPassword && cpForm?.confirmNewPassword && (cpForm?.newPassword === cpForm?.confirmNewPassword) ? "primary" : "basic"}
                    isDisabled={cpForm?.currentPassword && cpForm?.newPassword && cpForm?.confirmNewPassword && (cpForm?.newPassword === cpForm?.confirmNewPassword) ? false : true}
                    width="w-40"
                />
            </div>

        </form>
    )
}

export default ChangePassword