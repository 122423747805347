import { t } from 'i18next';
import { useContext, useEffect } from "react";
import { BsCalendar2WeekFill, BsClockFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import SelectDriver from "./SelectDriver";
import SelectLicensePlate from "./SelectLicensePlate";
import ShiftPlannerList from "./ShiftLists/ShiftPlannerList";
import { Tooltip } from 'antd';
import { ShiftPlannerContext } from '../../../../../Context/ShiftPlannerContext';
import ShiftPlannerBar from './Bars/ShiftPlannerBar';
import { iInfo } from '../../../../../Utility/Sources';
import Dropdown from '../../../../../Components/Dropdown';
import Input02 from '../../../../../Components/Input/Input02';
import { formatDate } from '../../../../../Utility/UtilityFunctions';
import Calendar from '../../../../../Components/Calendar';
import TimePicker from '../../../../../Components/Input/TimePicker';
import CommonInput from '../../../../../Components/Input/CommonInput';
import CommonButton from '../../../../../Components/Button/CommonButton';

const CreateShift = () => {
  const {
    setIsCreatingShift,
    setIsShiftDetailsID,
    isUnderMaintanance,
    setIsUnderMaintanance,
    startTimeValue,
    setStartTimeValue,
    endTimeValue,
    setEndTimeValue,
    commentValue,
    setCommentValue,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setClearOnSubmitShift,
    setCreateShift,
    licensePlateValue,
    selectedDriver,
    isCreatingShift,
    carId,
    setClearDetailsPanel,
    setCarId,
    setTempCarName,
  } = useContext(ShiftPlannerContext);

  const { company_id } = useParams();

  const changeCommentHandler = (e) => {
    setCommentValue(e.target.value);
  };

  const onSubmitHandler = async () => {
    // setClearOnSubmitShift(true);
    await setCreateShift({
      user_id: company_id,
      start_date: startDate,
      end_date: isUnderMaintanance ? endDate : startDate,
      start_time: startTimeValue,
      end_time: endTimeValue,
      license_plate: licensePlateValue,
      selected_driver: selectedDriver,
      comment: commentValue,
      is_under_maintainance: isUnderMaintanance,
    });

    setCommentValue("");
    // refreshFoo();
  };

  const onPickStartDateSelect = (time) => {
    console.log(time, "Selected Start Time");
    setStartTimeValue(time);
  };

  const onPickEndDateSelect = (time) => {
    console.log(endTimeValue, "Set End Time");
    setEndTimeValue(time);
  };

  const ChangeStartDateValue = async (name, value) => {
    setStartDate(value);
  };
  const ChangeEndDateValue = async (name, value) => {
    setEndDate(value);
  };

  useEffect(() => {
    setStartTimeValue("");
    setEndTimeValue("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (isCreatingShift) {
      setStartDate("");
      setEndDate("");
      setStartTimeValue("");
      setEndTimeValue("");
      setTempCarName("");
      setCarId("");
      setIsUnderMaintanance(false);
      setCommentValue("");
      // console.log("MODE: createShift! ", createShift);
    }
    setClearDetailsPanel(true);
    setIsShiftDetailsID(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectLicensePlateState = () => {
    if (isUnderMaintanance) {
      if (!startDate || !endDate || !startTimeValue || !endTimeValue) return true
      else return false
    } else {
      if (!startDate || !startTimeValue || !endTimeValue) return true
      else return false
    }
  }

  const setCanSubmit = () => {
    if (isUnderMaintanance) {
      if (startDate && endDate && startTimeValue && endTimeValue && carId && commentValue && (isUnderMaintanance || selectedDriver)) return true
      else return false
    } else {
      if (startDate && startTimeValue && endTimeValue && carId && commentValue && (isUnderMaintanance || selectedDriver)) return true
      else return false
    }
  }

  return (
    <>

      <ShiftPlannerBar />
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <ShiftPlannerList />
        <div className="col-span-12 mt-5 lg:col-span-8">
          <div className="card">
            <div className="p-1">
              <div className="font-semibold gs-text-main-black text-fs20">
                {t("Add Shift")}
              </div>

              <div className="flex flex-row items-center mt-5 mb-2">
                <input
                  className="gs-green-checkbox"
                  type="checkbox"
                  id="maintenance"
                  name="is_under_maintainance"
                  checked={isUnderMaintanance}
                  value={isUnderMaintanance}
                  onChange={() => {
                    setIsUnderMaintanance(!isUnderMaintanance);
                    isUnderMaintanance === true
                      ? setCommentValue("")
                      : setCommentValue("NA");

                    ChangeEndDateValue("end_date", "");
                  }}
                />
                <label htmlFor="maintenance" className="pl-2 mr-2 cursor-pointer select-none font-fw500 text-fs14 text-cBodyText" > {t("In Maintenance")} </label>
                <Tooltip title={"When a shift is in maintenance stage, company can't assign this shift to any request.\n Maintenance stage will start and end it certain period of time."}>
                  <img className="cursor-pointer" src={iInfo} alt="" />
                </Tooltip>
              </div>

              <div className="grid grid-cols-2 gap-2 md:gap-8 2xl:gap-12 h-[80px] mt-0">

                <Dropdown
                  width={null}
                  button={
                    <Input02
                      onChange={(date) => {
                        setClearOnSubmitShift(false);
                        ChangeStartDateValue("start_date", date);
                      }}
                      className="cursor-pointer"
                      className2="w-full"
                      // name="pickup_date"
                      value={startDate ? formatDate(startDate) : ""}
                      label={t("Start Date")}
                      type="text"
                      placeholder={t("Start Date")}
                      is_readonly={true}
                      icon={<BsCalendar2WeekFill className="h-[70%]" />}
                    />
                  }
                  body={
                    <Calendar
                      selectAction={(date) => {
                        setClearOnSubmitShift(false);
                        ChangeStartDateValue("start_date", date);
                      }}
                      init_date={startDate ? startDate : ""}
                    />
                  }
                />

                {
                  isUnderMaintanance && <Dropdown
                    width={null}
                    button={
                      <Input02
                        onChange={(date) => {
                          setClearOnSubmitShift(false);
                          ChangeEndDateValue("end_date", date);
                        }}
                        className="cursor-pointer"
                        className2="w-full"
                        name="end_date"
                        value={endDate ? formatDate(endDate) : ""}
                        label={t("End Date")}
                        type="text"
                        placeholder={t("End Date")}
                        is_readonly={true}
                        icon={<BsCalendar2WeekFill className="h-[70%]" />}
                        is_disabled={isUnderMaintanance}
                      />
                    }
                    body={
                      <Calendar
                        selectAction={(date) => {
                          setClearOnSubmitShift(false);
                          ChangeEndDateValue("end_date", date);
                        }}
                        init_date={endDate ? endDate : ""}
                      />
                    }
                  />
                }

              </div>

              <div className="grid grid-cols-2 gap-2 mb-12 md:gap-8 2xl:gap-12">
                <Dropdown
                  width={null}
                  button={
                    <Input02
                      value={startTimeValue ? startTimeValue : ""}
                      onChange={(e) => {
                        setClearOnSubmitShift(false);
                      }}
                      // onClick={() => {}}
                      className="cursor-pointer"
                      className2="w-full"
                      name="start_time"
                      label={t("Start Time")}
                      type="text"
                      placeholder={t("Start Time")}
                      icon={<BsClockFill className="h-[70%] cursor-pointer" />}
                    />
                  }
                  body={
                    <TimePicker
                      selectAction={onPickStartDateSelect}
                      init_time={startTimeValue ? startTimeValue : ""}
                    />
                  }
                />

                <Dropdown
                  width={null}
                  button={
                    <Input02
                      value={endTimeValue ? endTimeValue : ""}
                      onChange={(e) => {
                        setClearOnSubmitShift(false);
                      }}
                      onClick={() => { }}
                      className="cursor-pointer"
                      className2="w-full"
                      name="end_time"
                      label={t("End Time")}
                      type="text"
                      placeholder={t("End Time")}
                      icon={<BsClockFill className="h-[70%] cursor-pointer" />}
                    />
                  }
                  body={
                    <TimePicker
                      selectAction={onPickEndDateSelect}
                      init_time={endTimeValue ? endTimeValue : ""}
                    // init_avg={pickup_avg}
                    />
                  }
                />
              </div>

              <SelectLicensePlate name="license_plate" disabled={setSelectLicensePlateState()} isInMaintenance={isUnderMaintanance} />

              <div className="relative mt-s20">
                <SelectDriver isUnderMaintanance={isUnderMaintanance} disabled={setSelectLicensePlateState()} />
              </div>

              <div className={`text-sm text-left font-fw600 mb-2 text-cBodyText mt-5 ${isUnderMaintanance ? "opacity-50" : ""}`}> {t("Shift Instruction*")} </div>
              <div className={`mb-5 mt-2 ${isUnderMaintanance ? "opacity-50" : ""}`}>
                <CommonInput
                  textarea={true}
                  max_char_limit={500}
                  is_show_char_limit={true}
                  placeholder="Write instruction for driver"
                  name="comment"
                  value={isUnderMaintanance ? "" : commentValue}
                  onChange={changeCommentHandler}
                  disabled={isUnderMaintanance ? true : false}
                />
              </div>

              <div className="flex flex-row justify-between">
                <Link to={"/users/companies/details/" + company_id + "/shift-planner"}>
                  <CommonButton
                    btnLabel={t("Cancel")}
                    colorType="danger"
                    onClick={() => {
                      setIsCreatingShift(false);
                      setClearOnSubmitShift(true);
                    }}
                  />
                </Link>

                <div>
                  <CommonButton
                    btnLabel={t("Add Shift")}

                    onClick={onSubmitHandler}
                    isDisabled={setCanSubmit() ? false : true}
                    colorType={setCanSubmit() ? "primary" : "base"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateShift;
