/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import useGeneralStore from '../../../../../../App/stores/GeneralStore';
import useMapStore from '../../../../../../App/stores/MapStore';
import useTrackOnlineDriverStore, { useTrackOnlineDriverPatchStore } from '../../../../../../App/stores/TrackOnlineDriverStore';
import { NoImageIcon } from '../../../../../../App/Utility/ImageImports';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import CommonButtonOutlined from '../../../../../../Components/Button/CommonButtonOutlined';
import CommonImage from '../../../../../../Components/Image/CommonImage';
import EmptyMapDiv from '../../../../../../Components/Map/EmptyMapDiv';
import LimadiMap from '../../../../../../Components/Map/LimadiMap';
import Modal from '../../../../../../Components/Modal';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { BaseUrlSrc } from '../../../../../../Utility/url';
import { formatDate, timeDiffHours } from '../../../../../../Utility/UtilityFunctions';
import RoutePlannerList from "../Lists/RoutePlannerList";
import ReleaseStopModal from "../Modal/ReleaseStopModal";
import ConfirmDelivery from "../Popup/ConfirmDelivery";
import ConfirmHold from "../Popup/ConfirmHold";
// pages
import RoutePlannerBar from "../TitleBars/RoutePlannerBar";

const RoutePlannerDetails = () => {
  const { isShiftDetailsData } = useContext(ShiftPlannerContext);
  const {
    getRoutePlannerList,
    selectedStop,
    setSelectedStop,
    setSelectedRequest,
    setIsOpenFIrstAccordion,
    showHoldModal,
    setShowHoldModal,
    showIncompleteDeliveryModal,
    setShowIncompleteDeliveryModal,
    showDeliveryModal,
    setShowDeliveryModal,
    routeList,
    deleteCustomStop,
  } = useContext(TrackOnlineContext);

  const { isShowReleaseStopModal } = useTrackOnlineDriverStore();
  const { setShowReleaseStopModal, setShiftId, hasRoutePlannerMarkerPoint } = useTrackOnlineDriverPatchStore();

  const [showProductListModal, setShowProductListModal] = useState(false)
  const [targetStop, setTargetStop] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showImagePreview, setShowImagePreview] = useState(false)

  let { shift_id, company_id } = useParams();
  const { isOnline } = useGeneralStore()
  const { setTempPloyLine } = useMapStore()


  useEffect(() => {
    window.scrollTo(0, 0);
    setShiftId(shift_id);
    setSelectedStop(null);
    getRoutePlannerList(shift_id, true);
    return () => setTempPloyLine(null);
  }, [])

  useEffect(() => {
    if (routeList) {
      setTargetStop(
        routeList.find((item) => {
          if (item.id === selectedStop) {
            return item.stop_details;
          } else {
            return null;
          }
        })
      );
      // console.log("STOP DETAILS:::", targetStop);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStop]);

  const refreshFoo = async () => {
    if (isShiftDetailsData) {
      await getRoutePlannerList(isShiftDetailsData.id);
    }
  }

  const checkCanStopDelete = () => {
    if (targetStop.stop_details?.isCustom && targetStop?.status !== "ongoing" &&
      targetStop?.status !== "complete" && targetStop?.status !== "hold") return true;
    else return false;
  }

  return (
    <>

      {(hasRoutePlannerMarkerPoint && isOnline) ? <LimadiMap /> : <EmptyMapDiv content={isOnline ? 'No Pickup Points Available!' : 'You are not connected to internet!'} />}

      <RoutePlannerBar shift_id={shift_id} setTargetStop={setTargetStop} />
      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <RoutePlannerList setTargetStop={setTargetStop} />

        <div className="col-span-12 lg:col-span-7 mt-5 gs-text-main-black">
          {(targetStop) ? (
            <>
              {/* hg product list popup */}
              <CommonModal
                showModal={showProductListModal}
                setShowModal={setShowProductListModal}
                mainContent={
                  <div>
                    <div className="gs-text-main-black pb-5 text-lg flex justify-center w-full font-semibold">
                      {t("Packages")}
                    </div>
                    <div className='space-y-s10 max-h-[400px] overflow-y-auto'>
                      {targetStop &&
                        targetStop.stop_details &&
                        targetStop.stop_details.products?.length ? (
                        targetStop.stop_details.products.map((item, index) => (
                          <div
                            key={index}
                            className="flex item-center bg-cMoreLiteGrey p-5 rounded w-full"
                          >
                            <div className=" max-w-[30vw] truncate">
                              {t("Product code: ")}
                              {item.text && item.text}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="font-semibold italic text-slate-400 text-center py-5 text-sm">
                          {t("No Products Available")}
                        </div>
                      )}
                    </div>
                  </div>
                }
              />

              {
                // hb on-hold confirm modal
              }
              <CommonModal
                showModal={showHoldModal}
                setShowModal={setShowHoldModal}
                modalTitle={t("Confirmation")}
                mainContent={
                  <div>
                    <ConfirmHold shift_id={shift_id} company_id={company_id} />
                  </div>
                }
              />

              {/* hb confirm delivery modal */}
              <CommonModal
                showModal={showDeliveryModal}
                setShowModal={setShowDeliveryModal}
                mainContent={
                  <ConfirmDelivery status={1} company_id={company_id} shift_id={shift_id} />
                }
              />

              {/* hb NOT delivered modal */}
              <Modal

                show_modal={showIncompleteDeliveryModal}
                setShowModal={setShowIncompleteDeliveryModal}
                full_content={
                  <div className="gs-text-main-black inline-block w-full max-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                    <ConfirmDelivery status={0} company_id={company_id} shift_id={shift_id} />
                  </div>
                }
              />

              {/* review:  main details part starts */}
              <div className="card p-[20px] gs-text-main-black text-sm relative">
                {/* review: delete only if custom + init status */}
                {/* review: req-details-btn only if not-custom */}
                {/* {targetStop.isCustom && targetStop.status === "init" ? (
                  <div className="absolute right-[24px] cursor-pointer">
                    <img src={DeleteIcon} alt="delete-icon" />
                  </div>
                ) : (
                  ""
                )} */}

                {targetStop?.type === "custom" ? (
                  ""
                ) : (
                  <Link to="../request-list">
                    <div
                      onClick={() => {
                        setSelectedRequest(targetStop.stop_details.req_id);
                        setIsOpenFIrstAccordion(true);
                      }}
                      className="absolute right-[20px] cursor-pointer select-none"
                    >
                      <CommonButtonOutlined
                        btnLabel={t("Request Details")}
                      />
                    </div>
                  </Link>
                )}

                {/* StopDetails => UiD: {id} */}
                <div className="capitalize text-2xl font-semibold pb-[20px]">
                  {t("Stop Details")}
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">{t("Receiver's Name")}</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px] max-w-[18vw] truncate">
                      {targetStop.title}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">{t("Delivery Date")}</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px]">
                      {formatDate(targetStop.stop_details?.date)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">{t("Delivery Time")}</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px]">
                      {
                        targetStop?.stop_details?.start_time?.slice(0, -3) +
                        // formatDateOrTime(
                        //   targetStop.stop_details?.start_time +
                        //     targetStop.stop_details?.date
                        // ) +
                        " ( Avg - " +
                        timeDiffHours(
                          targetStop.stop_details?.end_time,
                          targetStop.stop_details?.start_time,
                          targetStop.stop_details?.date
                        ) +
                        " hr )"}
                      {/* {targetStop.start_time - targetStop.end_time} */}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">{t("Delivery Address")}</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <Tooltip title={targetStop.address}>
                      <div className="text-left pl-[40px] max-w-[20vw] truncate">
                        {targetStop.address}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">{t("Total Products")}</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div
                      onClick={() => {
                        setShowProductListModal(true);
                      }}
                      className="text-left pl-[40px] gs-text-link-color cursor-pointer select-none font-semibold"
                    >
                      {targetStop && targetStop.stop_details?.products
                        ? targetStop.stop_details?.products.length
                        : ""}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">{t("Comment")}</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px] w-full h-fit max-w-[20vw] truncate">
                      {targetStop ? targetStop.stop_details?.comment : "NA"}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body">
                  <div className="w-1/2 ">{t("Attachment")}</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    {targetStop?.stop_details &&
                      targetStop.stop_details.attachment ? (
                      <div className="text-left ml-[40px] w-full h-[250px] overflow-hidden rounded-[5px]">
                        <CommonImage
                          onClick={() => setShowImagePreview(true)}
                          src={targetStop.stop_details?.attachment}
                          dummyImage={NoImageIcon}
                          alt="attachment"
                          className="w-full h-full object-cover cursor-pointer select-none"
                        />
                      </div>
                    ) : (
                      <div className="ml-[40px] text-left">NA</div>
                    )}
                  </div>
                </div>



                {/* review: show if only ongoing */}
                {targetStop.status === "ongoing" ? (
                  <>
                    <div className="flex justify-between items-center pt-[40px]">
                      <div
                        onClick={() => setShowIncompleteDeliveryModal(true)}
                        className="gs-list-icon-btn"
                      >
                        {t("Not Delivered")}
                      </div>
                      <div
                        className="gs-list-icon-btn"
                        onClick={() => setShowHoldModal(true)}
                      >
                        {t("On Hold")}
                      </div>
                    </div>
                    <div className="pt-10 w-full">
                      <div
                        onClick={() => setShowDeliveryModal(true)}
                        className="gs-full-size-secondary-btn"
                      >
                        {t("Complete")}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <>



                  <div className={`w-full
                  ${(checkCanStopDelete() && targetStop?.status === "hold") ? "justify-between" : "justify-center"}`}>
                    {
                      checkCanStopDelete() ?
                        <div className="pt-5">
                          <CommonButton

                            btnLabel={t("Delete")}
                            colorType="danger"
                            onClick={() => setShowModal(true)}
                          /></div> : ""
                    }
                    {targetStop?.status === "hold" ?
                      <div className="pt-5">
                        <CommonButton
                          btnLabel={'Release'}
                          onClick={() => setShowReleaseStopModal(true)}
                        /></div> : ""
                    }

                  </div>

                </>
              </div>


            </>
          ) : (
            // hb:  if no item is selected
            <div className="card">
              <div className="flex justify-center items-center font-bold italic text-slate-400 min-h-[350px]">
                {t("Select an item to see details")}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* hb image preview modal */}
      <Modal
        show_modal={showImagePreview}
        setShowModal={setShowImagePreview}
        full_content={
          <div className="inline-block min-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-[10px]">
            <div className="">
              <div className="relative">
                <AiFillCloseCircle
                  onClick={() => setShowImagePreview(false)}
                  className="absolute top-0 right-0 text-2xl cursor-pointer select-none text-cRed z-20"
                />
                <div className="w-auto h-[80vh] rounded-[10px] overflow-hidden">
                  <CommonImage
                    src={targetStop?.stop_details?.attachment}
                    dummyImage={NoImageIcon}
                    alt="attachment"
                    className="p-0 w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* hr delete modal */}
      <CommonModal
        showModal={showModal}
        setShowModal={setShowModal}
        mainContent={
          <div>
            <div>
              <div className="relative">
                <div className="pb-5 text-center font-bold text-xl">
                  {t("Confirmation")}
                </div>
                <div className="pb-5 text-center text-fs16">
                  {t("Are you sure you want to delete this stop?")}
                </div>

                <div className="flex flex-row justify-center">
                  <CommonButton
                    btnLabel={t("Yes")}
                    onClick={() => {
                      deleteCustomStop(targetStop.stop_id);
                      refreshFoo();
                      setSelectedStop(null);
                      setShowModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        }
      />

      {/* Release Stop */}
      <ReleaseStopModal
        showModal={isShowReleaseStopModal}
        setShowModal={setShowReleaseStopModal}
        shift_id={shift_id}
        route_id={targetStop?.id}
        setTargetStop={setTargetStop}
        u_id={company_id}
      />

    </ >
  );
};

export default RoutePlannerDetails;
