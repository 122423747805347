/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useGeneralStore from '../../../../../../App/stores/GeneralStore';
import { checkCanShiftStart, checkIfShiftIsExpired } from '../../../../../../App/stores/ShiftPlannerStore';
import { useTrackOnlineDriverPatchStore } from '../../../../../../App/stores/TrackOnlineDriverStore';
import { InfoIcon } from '../../../../../../App/Utility/ImageImports';
import DefaultButton from '../../../../../../Components/Button/DefaultButton';
import EmptyMapDiv from '../../../../../../Components/Map/EmptyMapDiv';
import LimadiMap from '../../../../../../Components/Map/LimadiMap';
import Modal from '../../../../../../Components/Modal';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import XsLinearProgressBar from '../../../../../../Components/ProgressBar/XsLinearProgressBar';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { iInfoLocationIcon, iInfoPackageIcon, iPauseIcon, iResumeIcon, iStopIcon } from '../../../../../../Utility/Sources';
import { DistanceIntoKM, TimeIntoHours } from '../../../../../../Utility/UtilityFunctions';

import ShiftExpiredInfo from "../../ShiftPlanner/Details/ShiftExpiredInfo";
import ShiftInMaintenanceInfo from "../../ShiftPlanner/Details/ShiftInMaintenanceInfo";
import TrackList from "../Lists/TrackList";
import ConfirmDelivery from "../Popup/ConfirmDelivery";
import ConfirmHold from "../Popup/ConfirmHold";
import ConfirmPauseShift from "../Popup/ConfirmPauseShift";
import ConfirmStopShift from "../Popup/ConfirmStopShift";
import TrackListTitleBar from "../TitleBars/TrackListTitleBar";
import DriverInfo from './DriverInfo';
import ProductListModal from '../../../../../../Pages/Request/ProductListModal';

const TrackShiftDetails = () => {
  const { isShiftDetailsData, getShiftPlannerDetails } = useContext(ShiftPlannerContext);
  const { isOnline } = useGeneralStore();
  const { hasMarkerPoint } = useTrackOnlineDriverPatchStore();
  const [show_package_modal, setShowPackageModal] = useState(false);

  const {
    currentSelection,
    setIsOpenFIrstAccordion,
    takeShiftAction,
    showDeliveryModal,
    setShowDeliveryModal,
    setSelectedStop,
    showHoldModal,
    setShowHoldModal,
    showIncompleteDeliveryModal,
    setShowIncompleteDeliveryModal,
    showStopShiftModal,
    setShowStopShiftModal,
    setSelectedRequest,
    showPauseShiftModal,
    setShowPauseShiftModal,
    getTrackOnlineList,
    setCurrentSelection,
    setSearchKey,
    setCurrentFilterSelection,
  } = useContext(TrackOnlineContext);

  const [day, setDay] = useState(0);
  const [monthShort, setMonthShort] = React.useState("--");


  const { company_id } = useParams();

  useEffect(() => {
    setSearchKey("")
    setCurrentFilterSelection(0)
    // setCurrentSelection(null)
    getTrackOnlineList(true, company_id)
  }, []);

  useEffect(() => {
    if (currentSelection) {
      getShiftPlannerDetails(currentSelection);
    }
  }, [currentSelection]);

  useEffect(() => {
    if (isShiftDetailsData?.start_date) {
      let x = isShiftDetailsData?.start_date_formated?.toString();
      const y = x.substring(0, 2);
      x = x.substring(x.length - 4, x.length);
      setDay(y);
      setMonthShort(x);
    }
  }, [isShiftDetailsData]);

  return (
    <>
      {(hasMarkerPoint && isOnline) ? <LimadiMap /> : <EmptyMapDiv content={isOnline ? t("No Driver Online!") : t("You are not connected to internet!")} />}

      <TrackListTitleBar />

      <div onClick={() => console.log("isShiftDetailsData", isShiftDetailsData)} className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <TrackList />
        <div className="col-span-12 mt-5 lg:col-span-8">

          {/* on-hold confirm modal */}
          <CommonModal
            showModal={showHoldModal}
            setShowModal={setShowHoldModal}
            modalTitle={t("Confirmation")}
            mainContent={
              <div>
                <ConfirmHold company_id={company_id} />
              </div>
            }
          />

          {/*  stop shift ?? confirm modal */}
          <CommonModal
            showModal={showStopShiftModal}
            setShowModal={setShowStopShiftModal}
            mainContent={
              <div>
                <ConfirmStopShift company_id={company_id} />
              </div>
            }
          />

          {/* Pause shift ?? confirm modal */}
          <Modal
            show_modal={showPauseShiftModal}
            setShowModal={setShowPauseShiftModal}
            full_content={
              <div className="inline-block w-full max-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform bg-white rounded-lg shadow-xl gs-text-main-black">
                <ConfirmPauseShift company_id={company_id} />
              </div>
            }
          />

          {/* confirm delivery modal */}
          <Modal
            show_modal={showDeliveryModal}
            setShowModal={setShowDeliveryModal}
            full_content={
              <div className="inline-block w-full max-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform bg-white rounded-lg shadow-xl gs-text-main-black">
                <ConfirmDelivery status={1} company_id={company_id} />
              </div>
            }
          />

          {/* NOT delivered modal */}
          <Modal
            show_modal={showIncompleteDeliveryModal}
            setShowModal={setShowIncompleteDeliveryModal}
            full_content={
              <div className="inline-block w-full max-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform bg-white rounded-lg shadow-xl gs-text-main-black">
                <ConfirmDelivery status={0} company_id={company_id} />
              </div>
            }
          />

          {/* check if isShiftDetailsData empty or not */}
          {!currentSelection ? (
            <div className="card">
              <div className="flex justify-center items-center font-bold italic text-slate-400 min-h-[350px]">
                {t("Select an item to see details")}
              </div>
            </div>
          ) : isShiftDetailsData?.status === "ongoing" ||
            isShiftDetailsData?.status === "break" ? (
            //p  on-going shift details
            <div className="card">
              <div className="flex items-center justify-between pb-4">
                <div className="py-2 text-2xl font-bold">{t("Shift Details")}</div>

                {isShiftDetailsData?.status === "ongoing" &&
                  isShiftDetailsData?.on_going_stop ? (
                  <Link to={`route-planner/${isShiftDetailsData?.id}`}>
                    <button className="text-cPrimary hover:text-cWhite border-[1.5px] border-cPrimary hover:bg-cPrimary font-medium rounded-lg text-[16px] py-2 px-5 text-center ">
                      {t("Go to Route Planner")}
                    </button>
                  </Link>
                ) : (
                  ""
                )}
              </div>



              <div className="bg-cListItem rounded-xl flex card shadow-none p-[20px]">

                {/* //r driver information if shift isn't in maintenance */}
                <DriverInfo isShiftDetailsData={isShiftDetailsData} />

                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <div className="flex flex-col items-center justify-center font-bold">
                      <span className="text-[18px] font-semibold gs-text-main-black">
                        {t("Started")}:{" "}
                        {isShiftDetailsData?.start_time &&
                          isShiftDetailsData?.start_time.slice(0, -3)}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    <span className="text-[14px], font-semibold gs-text-body">
                      {("End")}:{" "}
                      {isShiftDetailsData?.end_time
                        ? isShiftDetailsData?.end_time.slice(0, -3)
                        : "00 : 00"}{" "}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row justify-between w-full pt-[15px] text-[14px] font-semibold gs-text-body">
                  <span>
                    {t("Break")}:{" "}
                    {isShiftDetailsData?.breaks
                      ? TimeIntoHours(isShiftDetailsData?.breaks)
                      : "00"}{" "}
                    {t("hrs")}
                  </span>
                  <span>
                    {("Drive")}:{" "}
                    {isShiftDetailsData.driven
                      ? DistanceIntoKM(isShiftDetailsData.driven)
                      : "00"}{" "}
                    {t("KM")}
                  </span>
                </div>
                <div className="flex items-center justify-between w-full pt-5">
                  <div className="flex flex-col">
                    <div
                      onClick={() => {
                        setShowStopShiftModal(true);
                      }}
                      className="flex justify-center items-center w-[150px] h-[48px] px-5 py-1 bg-white rounded-[10px] shadow-sm text-center mb-1 cursor-pointer select-none"
                    >
                      <span className="pr-3 font-semibold">{t("Stop")}</span>
                      <img
                        src={iStopIcon}
                        alt=""
                        className="w-[30px] h-[30px]"
                      />
                    </div>
                    <div className="text-[10px] font-semibold gs-text-body pt-[4px]">
                      {t("Stop shift if you don’t continue")}
                    </div>
                  </div>
                  <div className="flex flex-col items-end">
                    {isShiftDetailsData.status === "ongoing" ? (
                      <>
                        <div
                          onClick={() => {
                            setShowPauseShiftModal(true);
                          }}
                          className="flex justify-center items-center w-[150px] h-[48px] px-5 py-1 bg-white rounded-[10px] shadow-sm text-center mb-1 cursor-pointer select-none"
                        >
                          <span className="pr-3 font-semibold capitalize">{t("Pause")}</span>
                          <img
                            src={iPauseIcon}
                            alt=""
                            className="w-[30px] h-[30px]"
                          />
                        </div>
                        <div className="text-[10px] font-semibold gs-text-body pt-[4px]">
                          {t("Pause shift If you need a break")}
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col items-end">
                        <div
                          onClick={() => {
                            takeShiftAction("resume", company_id);
                          }}
                          className="flex justify-center items-center w-[150px] h-[48px] px-5 py-1 bg-white text-cMainBlack rounded-[10px] shadow-sm text-center mb-1 cursor-pointer select-none"
                        >
                          <span className="pr-2 font-semibold drop-shadow-md">
                            {t("Resume")}
                          </span>
                          <img
                            src={iResumeIcon}
                            alt="resume-shift"
                            className="w-[30px] h-[30px]"
                          />
                        </div>
                        <div className="text-[10px] font-semibold gs-text-body pt-[4px]">
                          {t("Resume when you are finished taking a break")}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* b      request button */}
              <div className="flex justify-between px-1 pt-5 text-center gs-text-icon-color">
                <Link to="request-list">
                  <div
                    onClick={() => {
                      setIsOpenFIrstAccordion(false);
                      setSelectedRequest(null);
                    }}
                    className="flex flex-col p-2 bg-cListItem rounded-br5 w-[140px] cursor-pointer select-none"
                  >
                    <span className="text-sm">{t("Request")}</span>
                    <span className="text-[18px] font-semibold gs-text-main-black">
                      {isShiftDetailsData.request_completed}/
                      {isShiftDetailsData.request_count}
                    </span>

                    <div className="mt-[5px] mb-[15px]">
                      <XsLinearProgressBar
                        percentage={
                          isShiftDetailsData.request_count
                            ? Math.round(
                              (isShiftDetailsData.request_completed /
                                isShiftDetailsData.request_count) *
                              100
                            )
                            : 0
                        }
                      />
                    </div>
                  </div>
                </Link>

                {/*y       stops button */}
                <Link to="stop-details-of-shift">
                  <div className="flex flex-col p-2 bg-cListItem rounded-br5  w-[140px] cursor-pointer select-none">
                    <span className="text-sm">{t("Stops")}</span>
                    <span className="text-[18px] font-semibold gs-text-main-black">
                      {isShiftDetailsData.stops_completed}/
                      {isShiftDetailsData.stops_count}
                    </span>

                    <div className="mt-[5px] mb-[15px]">
                      <XsLinearProgressBar
                        percentage={
                          isShiftDetailsData.stops_count
                            ? Math.round(
                              (isShiftDetailsData.stops_completed /
                                isShiftDetailsData.stops_count) *
                              100
                            )
                            : 0
                        }
                      />
                    </div>
                  </div>
                </Link>

                {/*g       products / packages button */}
                <Link to="package-list">
                  <div className="flex flex-col p-2 bg-cListItem rounded-br5  w-[140px] cursor-pointer select-none">
                    <span className="text-sm">{t("Packages")}</span>
                    <span className="text-[18px] font-semibold gs-text-main-black">
                      {isShiftDetailsData.products_completed}/
                      {isShiftDetailsData.products_count}
                    </span>

                    <div className="mt-[5px] mb-[15px]">
                      <XsLinearProgressBar
                        percentage={
                          isShiftDetailsData.products_count
                            ? Math.round(
                              (isShiftDetailsData.products_completed /
                                isShiftDetailsData.products_count) *
                              100
                            )
                            : 0
                        }
                      />
                    </div>
                  </div>
                </Link>
              </div>

              {/* hr New Addition to ongoing stop if ongoing stop is available from api ! */}
              {isShiftDetailsData.on_going_stop ? (
                <div className="min-h-20 w-full  mx-auto my-5 p-[24px] bg-cListItem rounded-[10px] shadow-sm">
                  <div className="flex items-center justify-between w-full">
                    <div className="font-semibold text-xl max-w-[30vw] truncate">
                      {isShiftDetailsData?.on_going_stop
                        ? isShiftDetailsData?.on_going_stop?.title
                        : "NA"}
                    </div>
                    <div>
                      <Link
                        to={`stop-details-of-shift-tracked?id=${isShiftDetailsData?.on_going_stop?.id}`}
                      >
                        <img
                          onClick={() => {
                            // eslint-disable-next-line no-lone-blocks
                            {
                              isShiftDetailsData.on_going_stop &&
                                setSelectedStop(isShiftDetailsData.on_going_stop.id);
                            }
                          }}
                          src={InfoIcon}
                          alt=""
                          className="w-[20px] h-[20px] cursor-pointer select-none"
                        />
                      </Link>
                    </div>
                  </div>

                  <div className="w-full flex justify-between items-center pt-[10px]">
                    <div className="font-normal text-sm max-w-[30vw] truncate">
                      {isShiftDetailsData.on_going_stop
                        ? isShiftDetailsData.on_going_stop.address
                        : "NA"}
                    </div>
                    <div>
                      <a target={'_blank'} rel='noreferrer' href={`http://maps.google.com/?q=${isShiftDetailsData?.on_going_stop?.address}`}>
                        <img
                          src={iInfoLocationIcon}
                          alt=""
                          className="w-[20px] h-[20px] cursor-pointer select-none"
                        />
                      </a>
                    </div>
                  </div>

                  {isShiftDetailsData.on_going_stop.products.length ? (
                    <div className="w-full flex justify-between items-center pt-[10px]">
                      <div className="font-normal text-sm max-w-[30vw] truncate">
                        {isShiftDetailsData?.on_going_stop?.products?.length
                          ? isShiftDetailsData?.on_going_stop?.products
                            ?.length + t(" Products")
                          : t("0 Products")}
                      </div>
                      <div
                        onClick={() => setShowPackageModal(true)}
                      >
                        <img
                          src={iInfoPackageIcon}
                          alt=""
                          className="w-[20px] h-[20px] cursor-pointer select-none"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* hb stop actions for ongoing stop */}
                  <div className="flex justify-between items-center pt-[40px]">
                    <div
                      onClick={() => setShowIncompleteDeliveryModal(true)}
                      className="gs-list-icon-btn"
                    >
                      {t("Not Delivered")}
                    </div>
                    <div
                      className="gs-list-icon-btn"
                      onClick={() => setShowHoldModal(true)}
                    >
                      {t("On Hold")}
                    </div>
                  </div>
                  <div className="w-full pt-10">
                    <div
                      onClick={() => setShowDeliveryModal(true)}
                      className="gs-full-size-secondary-btn"
                    >
                      {t("Complete")}
                    </div>
                  </div>
                </div>
              ) : (
                // g if route not optimized, btn to route planner
                <Link to={`route-planner/${isShiftDetailsData.id}`}>
                  <div className="flex justify-center px-5 py-3 mt-5 bg-cListItem hover:gs-primary gs-text-primary text-cBrand hover:text-cListItem hover:bg-cBrand rounded-xl">
                    <span className="text-sm font-semibold text-center">
                      {isShiftDetailsData.is_optimized ? t("Go to Route Planner") : t("Your Route is not Optimized, Go to Route Planner")}
                    </span>
                  </div>
                </Link>
              )}
            </div>
          ) : (
            // MERGED OLD DETAILS PORTION
            <div className="card">
              <div className="flex items-center justify-between pb-4">
                <div className="py-2 text-2xl font-bold">{t("Shift Details")}</div>
                {isShiftDetailsData?.status === "ongoing" ? (
                  <Link to={`route-planner/${isShiftDetailsData.id}`}>
                    <button
                      type="submit"
                      className="text-cPrimary hover:text-cWhite border-[1.5px] border-cPrimary hover:bg-cPrimary font-medium rounded-lg text-[16px] py-2 px-5 text-center "
                    >
                      {t("Go to Route Planner")}
                    </button>
                  </Link>
                ) : (
                  ""
                )}
              </div>
              <div className="flex shadow-none bg-cListItem rounded-xl card">

                {/* //! driver information if shift isn't in maintenance */}
                {
                  (isShiftDetailsData?.is_maintenance === 0) ? <DriverInfo isShiftDetailsData={isShiftDetailsData} /> : <></>
                }

                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">

                    <div className="flex flex-col justify-center items-center gs-line-inside-box rounded-full h-[60px] w-[60px]">
                      <span className="text-xl font-semibold text-cListIcon">
                        {day}
                      </span>
                      <span className="text-cListIcon text-[10px]">
                        {monthShort}
                      </span>
                    </div>
                    <div className="flex flex-col pl-5 text-sm font-semibold">
                      <div>{isShiftDetailsData?.weekday}</div>
                      <div className="max-w-[10vw] truncate">
                        {isShiftDetailsData?.car
                          ? isShiftDetailsData.car.name
                          : "NA"}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end font-semibold">
                    <span className="text-sm">
                      {isShiftDetailsData?.start_time?.slice(0, -3)} -{" "}
                      {isShiftDetailsData?.end_time?.slice(0, -3)}
                    </span>
                    <span className="text-sm">
                      {isShiftDetailsData?.car &&
                        isShiftDetailsData.car.car_license_plate_number}
                    </span>
                  </div>
                </div>

                {isShiftDetailsData?.is_maintenance ? null : (
                  <div className="flex flex-row justify-around w-full pt-5">
                    {/*  request btn */}
                    <Link to="request-list">
                      <div
                        onClick={() => setSelectedRequest(null)}
                        className="flex flex-col items-center justify-center bg-white w-[140px] h-[78px] py-3 rounded-lg"
                      >
                        <span className="text-[12px] gs-text-icon-color">
                          {t("Request")}
                        </span>
                        <span className="text-lg font-semibold">
                          {isShiftDetailsData?.request_completed}/
                          {isShiftDetailsData?.request_count}
                        </span>
                      </div>
                    </Link>

                    {/*  stops button */}
                    <Link to="stop-details-of-shift">
                      <div className="flex flex-col items-center justify-center bg-white w-[140px] h-[78px] py-3 rounded-lg">
                        <span className="text-[12px] gs-text-icon-color">
                          {t("Stops")}
                        </span>
                        <span className="text-lg font-medium">
                          {isShiftDetailsData?.stops_completed}/
                          {isShiftDetailsData?.stops_count}
                        </span>
                      </div>
                    </Link>

                    {/*  products / packages button */}
                    <Link to="package-list">
                      <div className="flex flex-col items-center justify-center bg-white w-[140px] h-[78px] py-3 rounded-lg">
                        <span className="text-[12px] gs-text-icon-color">
                          {t("Packages")}
                        </span>
                        <span className="text-lg font-medium">
                          {isShiftDetailsData?.products_completed}/
                          {isShiftDetailsData?.products_count}
                        </span>
                      </div>
                    </Link>
                  </div>
                )}

                {/* comments */}
                <div className="flex flex-col items-start justify-start w-full pt-5 text-sm">
                  <span className=" font-bold text-[14px]">{t("Comment")}</span>
                  <span className="w-full h-auto text-cBodyText">
                    {isShiftDetailsData?.comment
                      ? isShiftDetailsData.comment
                      : "NA"}
                  </span>
                </div>

                {isShiftDetailsData?.is_maintenance ?
                  <ShiftInMaintenanceInfo />
                  :
                  checkIfShiftIsExpired({ shiftInfo: isShiftDetailsData }) ?
                    <ShiftExpiredInfo />
                    :
                    <div className="flex justify-center pt-5">
                      {
                        checkCanShiftStart({ shiftInfo: isShiftDetailsData }) ?
                          <DefaultButton onSubmit={() => takeShiftAction("start", company_id)} label={'Start Shift'} />
                          :
                          <DefaultButton label={`Start in ${isShiftDetailsData?.starts_in}`} canSubmit={false} width="60px" />
                      }
                    </div>
                }
              </div>
            </div>
          )}
        </div>
        {isShiftDetailsData && <ProductListModal showModal={show_package_modal} setShowModal={setShowPackageModal} products={isShiftDetailsData?.products} />}
      </div>
    </>
  );
};

export default TrackShiftDetails;
