// ! React Toastify
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setFirebaseDeviceTokenFoo } from '../App/stores/GeneralStore';
toast.configure()


export const Toastr = (msg, type = 'error') => {
    // toast.error(msg, { autoClose: 2000, position: "top-center"});
    toast.error(msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: type,
        theme: "dark",
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}


export const ToastrLoading = (msg = "Please wait...", action = 'start', type = 'success', the_toastr) => {
    const options = {
        position: "bottom-right",
        autoClose: 2000,
        type: type,
        theme: "dark",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
    }

    if (action === 'start') {
        return toast.loading(msg, options)
    } else if (action === 'stop') {
        toast.update(the_toastr, { render: msg, type: type, isLoading: false, ...options });
    }
}


export const formatDate = (date, withTime = false) => {

    if (!date) return null;
    const months = [];
    months["en"] = [
        ". Jan",
        ". Feb",
        ". Mar",
        ". Apr",
        ". May",
        ". Jun",
        ". Jul",
        ". Aug",
        ". Sep",
        ". Oct",
        ". Nov",
        ". Dec",
    ];
    months["da"] = [
        ". jan.",
        ". feb.",
        ". mar.",
        ". apr.",
        ". maj",
        ". juni",
        ". juli",
        ". aug.",
        ". sept.",
        ". okt.",
        ". nov.",
        ". dec.",
    ];
    const targetDate = new Date(date);
    const hours = targetDate.getHours();
    const minutes = targetDate.getMinutes();

    if (withTime)
        return `${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}${months["en"][new Date(date).getMonth()]} ${new Date(date).getFullYear()}, ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    else
        return `${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}${months["en"][new Date(date).getMonth()]} ${new Date(date).getFullYear()}`;
};


export const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}


export const calculateDate = (end_date, start_date) => {
    const date1 = new Date(start_date);
    const date2 = new Date(end_date);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export const PageTitle = (newTitle) => {
    return (document.title = newTitle);
};

export const CalculateMonthsYears = (days) => {
    let month = 0;
    let year = 0;
    if (days < 365) {
        month = Math.round(days / 30);
        return {
            month: month,
            year: year,
        };
    } else {
        year = days / 365;
        year = parseInt(year);
        days = (days - (year * 365));
        month = parseInt(days / 30)
        return {
            month: month,
            year: year,
        };
    }
};


export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


export const DistanceIntoKM = (meterValue) => {
    return (meterValue / 1000).toFixed(1);
};

export const TimeIntoHours = (secondValue) => {
    return (secondValue / 3600).toFixed(1);
};

export const formatDateOrTime = (dateTime, type) => {
    const date = new Date(dateTime);
    if (type === "date") {
        return `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}. ${date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()
            }. ${date.getFullYear()}`;
    } else {
        //return time
        return `${date.getHours()}:${date.getMinutes()}`;
    }
};

export const formatTime = (time) => {
    if (!time) return time
    const [hours, minutes] = time?.split(":");
    const formattedTime = `${hours}:${minutes}`;
    return formattedTime
};

export const timeDiffHours = (dt2, dt1, startDate) => {
    //create date format
    var timeStart = new Date(startDate + "T" + dt1);
    var timeEnd = new Date(startDate + "T" + dt2);

    var diff = (timeEnd - timeStart) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
};


export const extractDate = (date) => {
    const t_date = new Date(date);
    let month = (t_date.getMonth() + 1)
    if (month < 10) month = "0" + month?.toString();
    // return t_date;
    return t_date.getFullYear() + "-" + month + "-" + ((t_date.getDate() < 9) ? "0" + t_date.getDate() : t_date.getDate())
}


export const incrementDate = (date, numberOfDays) => {
    date = extractDate(date);
    const new_date = new Date(date)
    new_date.setDate(new_date.getDate() + numberOfDays);
    return extractDate(new_date);
}

export const dateDifference = (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
    return Math.abs(diffDays);
};


export const getFormatedStringFromDays = (numberOfDays) => {

    const years = Math.floor(parseInt(numberOfDays) / 365);
    const months = Math.floor(parseInt(numberOfDays) % 365 / 30);
    const days = Math.floor(parseInt(numberOfDays) % 365 % 30);

    const yearsDisplay = years > 0 ? years + (years === 1 ? " year " : " years ") : "";
    const monthsDisplay = months > 0 ? months + (months === 1 ? " month " : " months ") : "";
    const daysDisplay = days > 0 ? days + (days === 1 ? " day" : " days") : "";
    return yearsDisplay + monthsDisplay + daysDisplay;
}
export function HasSpecialChar(password) {
    var specialChars = "!@#$%^&*()_+{}[]|:;'<>,.?/~`";
    for (var i = 0; i < password.length; i++) {
        if (specialChars.indexOf(password.charAt(i)) !== -1) {
            return true;
        }
    }
    return false;
}

export function HasNumbers(password) {
    return /\d/.test(password);
}

export function HasUpperAndLowerCase(password) {
    return /^(?=.*[a-z])(?=.*[A-Z])/.test(password);
}

export const checkValidPassword = (password) => {
    if (password.length < 8) {
        return ({
            status: false,
            message: "Password must be at least 8 characters long"
        });
    }

    if (!HasSpecialChar(password)) {
        return ({
            status: false,
            message: "Password must be at least one special character."
        });
    }

    if (!HasNumbers(password)) {
        return ({
            status: false,
            message: "Password must contain at least one number."
        });
    }


    if (!HasUpperAndLowerCase(password)) {
        return ({
            status: false,
            message: "Password must contain at least one uppercase and one lowercase."
        });
    }


    return ({
        status: true,
        message: "Valid password."
    });
}

export const CheckWhiteSpace = (value) => {
    if (value.trim().length === 0) {
        return false;
    }
    return true;
}

export const FullFormDataValidation = (formObject) => {

    for (var key in formObject) {
        // console.log("DATA KEY", key, formObject[key]);        
        if (!CheckWhiteSpace(formObject[key])) return false;
    }

    return true;
}

export const defineNotPlannedDeliveryDate = (pickup_date, delivery_time, pickup_time) => {

    if (!delivery_time || delivery_time === '') return formatDate(pickup_date);
    else {
        if (pickup_time > delivery_time) return formatDate(new Date(pickup_date).setDate(new Date(pickup_date).getDate() + 1));
        else return formatDate(pickup_date);
    };
}

export function isValidUrl(url) {
    // Regular expression pattern for a valid website URL
    var pattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(\/.*)?$/i;
    // Test the input URL against the pattern
    return pattern.test(url);
}

export const checkIsCustomerProfileShow = (type) => {
    if (type === 'invitations' || type === 'not_planned' || type === 'awarded' || type === 'ongoing' || type === 'complete' || type === 'history') return true;
    else return false;
}
export function CheckEmailValidity(email) {
    let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(email);
}

export const removeSecFromDeliveryTime = (time) => {
    if (!time) return null;
    if (typeof time !== 'string') {
        console.log("Invalid time format");
        return null;
    }
    const time_array = time.split(":");
    const hour = time_array[0] ?? '';
    const min = time_array[1] ?? '';
    if (hour === '' || min === '') return '';
    return `${hour}:${min}`;
}

export const validateDateTime = (start_date, start_time, end_date, end_time) => {
    const start_date_time = new Date(start_date + " " + start_time);
    const end_date_time = new Date(end_date + " " + end_time);
    if (end_date_time < start_date_time) {
        Toastr('Invalid date time!', "warning");
        return false
    } else return true;
}


export function formatTimeHourMinutes(timeString) {
    // Split the time string into hours, minutes, and seconds
    if (timeString) {
        const [hours, minutes] = timeString?.split(':');

        // Return the formatted time string
        return `${hours}:${minutes}`;
    }
}

export function getOrdinalNumber(n) {
    if (n === 0) {
        return "0";
    }
    const suffixes = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"];
    const lastTwoDigits = n % 100;
    const lastDigit = n % 10;
    const suffix = suffixes[lastTwoDigits] || suffixes[lastDigit] || suffixes[0];
    if (n >= 10 && n <= 20)
        return `${n}${suffixes[0]}`;
    else
        return `${n}${suffix}`;
}

export function secondsToHms(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    const hoursString = hours?.toString().padStart(2, '0');
    const minutesString = minutes?.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}`;
}

// e        LOGs        
export function LogDanger(message, value) {
    console.log('%c' + message, 'background: #f40e44; color: #ffffff; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogToDo(message, value) {
    console.log('%c' + message, 'background: #f4ef4b; color: #000000; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogSuccess(message, value) {
    console.log('%c' + message, 'background: #47ff90; color: #000000; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogWarning(message, value) {
    console.log('%c' + message, 'background: #FC4C02; color: #ffffff; font-weight: bold; padding:15px; border-radius: 1500px', value);
}


export const calculateDistance = (data) => {
    let distance = 0;
    let unit = 'KM';
    distance = data / 1000;
    if (distance < 1) {
        distance = distance * 1000;
        distance = Math.round(distance);
        unit = data > 1000 ? 'M' : "KM";
    } else {
        distance = Math.round(distance);
    }

    return {
        distance: distance ?? 0,
        unit: unit
    }
}

// export const calculateDistance = (data) => {
//     let distance = 0;
//     let unit = 'M'; // Default unit is meters

//     const value = data === null ? 0 : parseFloat(data);

//     if (value >= 1000) {
//         distance = value / 1000; // Convert to kilometers
//         unit = 'KM';
//     } else {
//         distance = Math.round(value); // Keep distance in meters
//     }

//     return {
//         distance: distance ?? 0,
//         unit: unit
//     }
// }







export const dateDiffCalendar = (startingDateInput, endingDateInput, lastAction = false) => {

    if (lastAction) startingDateInput = startingDateInput.setDate(startingDateInput.getDate() + 1);

    let startingDate = new Date(startingDateInput).setHours(0, 0, 0, 0);

    let endingDate = new Date(endingDateInput).setHours(0, 0, 0, 0);

    let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
    if (!endingDate) {
        endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    let endDate = new Date(endingDate);
    if (startDate > endDate) {
        const swap = startDate;
        startDate = endDate;
        endDate = swap;
    }
    const startYear = startDate.getFullYear();
    const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
        if (monthDiff > 0) {
            monthDiff--;
        } else {
            yearDiff--;
            monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
    }
    let yearString = yearDiff > 1 ? " years " : " year ";
    let monthString = monthDiff > 1 ? " months " : " month ";
    let dayString = dayDiff > 1 ? " days" : " day";
    return (yearDiff ? (yearDiff + yearString) : "") + (monthDiff ? (monthDiff + monthString) : "") + (dayDiff ? (dayDiff + dayString) : '0 day ');
}

export function isObjectEmpty(obj) {
    return typeof obj === 'object' && Object.keys(obj).length === 0;
}

export function countEmptySpaces(inputString) {
    // Use a regular expression to match strings that only contain spaces or multiple consecutive spaces
    const spaceRegex = /^[\s]+$/;

    if (spaceRegex.test(inputString)) {
        return true; // Allow all other strings
    } else {
        return false; // Disallow strings that only consist of spaces or contain multiple consecutive spaces
    }
}

export function convertATime(date) {
    if (date) {
      const targetDate = new Date(date);
      const hours = targetDate.getHours();
      const minutes = targetDate.getMinutes();
      
      // Use template literals to format the time
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    } else {
      return "00:00";
    }
  }
  


export const initializeFirebase = (is_logged_in, firebase) => {
    if (is_logged_in) {
      // initialize firebase
      let messaging = null;
      if (firebase.messaging.isSupported()) {
        messaging = firebase.messaging();
      }
      messaging.requestPermission().then(() => {
        return messaging.getToken()
      }).then(token => {
        setFirebaseDeviceTokenFoo(token);
        // console.log('Token : ', token)
      }).catch((err) => {
        console.log(err);
  
      })
    }
  }