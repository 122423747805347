import { t } from 'i18next';
import { useContext, useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { Link, useLocation, useParams } from "react-router-dom";
import { NoImageIcon } from '../../../../../../App/Utility/ImageImports';
import CommonButton from '../../../../../../Components/Button/CommonButton';
import CommonImage from '../../../../../../Components/Image/CommonImage';
import Modal from '../../../../../../Components/Modal';
import CommonModal from '../../../../../../Components/Modal/CommonModal';
import { ShiftPlannerContext } from '../../../../../../Context/ShiftPlannerContext';
import { TrackOnlineContext } from '../../../../../../Context/TrackOnlineContext';
import { BaseUrlSrc } from '../../../../../../Utility/url';
import { formatDate, formatDateOrTime, timeDiffHours } from '../../../../../../Utility/UtilityFunctions';
import StopList from "../../TrackOnline/Lists/StopList";
import ConfirmDelivery from "../../TrackOnline/Popup/ConfirmDelivery";
import ConfirmHold from "../../TrackOnline/Popup/ConfirmHold";
import StopListBar from "../../TrackOnline/TitleBars/StopListBar";

const StopDetailsSp = () => {
  const {
    isShiftHistory,
    setClearDetailsPanel,
    setClearHistoryDetailsPanel,
    setClearRequestDetailsPanel,
    isShiftDetailsData,
  } = useContext(ShiftPlannerContext);
  const {
    selectedStop,
    setSelectedRequest,
    setIsOpenFIrstAccordion,
    showHoldModal,
    setShowHoldModal,
    showIncompleteDeliveryModal,
    setShowIncompleteDeliveryModal,
    showDeliveryModal,
    setShowDeliveryModal,
    stopList,
    setSelectedStop,
    deleteCustomStop,
  } = useContext(TrackOnlineContext);

  const { company_id } = useParams();

  const [showDelModal, setShowDelModal] = useState(false);
  const [showProductListModal, setShowProductListModal] = useState(false);
  const [targetStop, setTargetStop] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showImagePreview, setShowImagePreview] = useState(false);

  // hb: to check and find which shift is selected and get their stop
  useEffect(() => {
    if (stopList) {
      setTargetStop(
        stopList.find((item) => {
          // console.log(item);
          if (item.id === selectedStop) {
            console.log("DATA FOUND:::", item);
            return item;
          } else {
            return null;
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStop]);

  useEffect(() => {
    if (targetStop) {
      setSelectedStop(targetStop.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetStop]);

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/track-online-driver/stop-details-of-shift-tracked"
    ) {
      //
    } else {
      setTargetStop(null);
      setSelectedStop(null);
    }
    setClearDetailsPanel(false);
    setClearHistoryDetailsPanel(false);
    // console.log("location.pathname::::::", location.pathname);
    window.scrollTo(0, 0);
    // getRoutePlannerList(isShiftDetailsData.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/*hb: title bar of this section */}
      {isShiftHistory ? (
        <StopListBar backLink={("/users/companies/details/" + company_id + "/shift-planner/shift-history")} />
      ) : (
        <StopListBar backLink={("/users/companies/details/" + company_id + "/shift-planner")} />
      )}

      {/* hg product list popup */}
      <CommonModal
        showModal={showProductListModal}
        setShowModal={setShowProductListModal}
        modalTitle={t("Packages")}
        mainContent={
          <div>
            <div className='max-h-[300px] overflow-y-auto'>
              {targetStop && targetStop && targetStop.products?.length ? (
                targetStop.products.map((item, index) => (
                  <div
                    key={index}
                    className="flex item-center bg-cMoreLiteGrey m-[10px] p-[10px] rounded"
                  >
                    <div className="max-w-[20vw] truncate">
                      {t("Product code: ")}
                      {item.text && item.text}
                    </div>
                  </div>
                ))
              ) : (
                <div className="font-semibold italic text-slate-400 text-center py-5 text-sm">
                  {t("No Products Available")}
                </div>
              )}
            </div>
          </div>
        }
      />

      {
        // hb on-hold confirm modal
      }
      <CommonModal
        showModal={showDelModal}
        setShowModal={setShowDelModal}
        modalTitle={t("Confirmation")}
        mainContent={
          <div>
            <div>
              <div className="py-5 text-center text-fs16">
                {t("Are you sure you want to make the stop On Hold?")}
              </div>

              <div className="flex flex-row justify-center">
                <CommonButton
                  btnLabel={t("Yes")}
                  onClick={() => {
                    setShowDelModal(false);
                  }}
                />
              </div>
            </div>
          </div>
        }
      />

      <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">
        <StopList />
        <div className="col-span-12 lg:col-span-8 mt-5">
          {targetStop ? (
            // hr modals
            <>
              {
                // hb on-hold confirm modal
              }
              <CommonModal
                showModal={showHoldModal}
                setShowModal={setShowHoldModal}
                modalTitle={t("Confirmation")}
                mainContent={
                  <div>
                    <ConfirmHold />
                  </div>
                }
              />

              {/* hb confirm delivery modal */}
              <Modal
                show_modal={showDeliveryModal}
                setShowModal={setShowDeliveryModal}
                full_content={
                  <div className="gs-text-main-black inline-block w-full max-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                    <ConfirmDelivery status={1} />
                  </div>
                }
              />

              {/* hb NOT delivered modal */}
              <Modal
                show_modal={showIncompleteDeliveryModal}
                setShowModal={setShowIncompleteDeliveryModal}
                full_content={
                  <div className="gs-text-main-black inline-block w-full max-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
                    <ConfirmDelivery status={0} />
                  </div>
                }
              />

              {/* review:  main details part starts */}
              <div className="card p-[24px] gs-text-main-black text-sm relative">
                {!targetStop?.isCustom && targetStop?.req_id ? (
                  <Link to={"/users/companies/details/" + company_id + "/shift-planner/request-details"}>
                    <div
                      onClick={() => {
                        setSelectedRequest(targetStop?.req_id);
                        setIsOpenFIrstAccordion(true);
                        setClearRequestDetailsPanel(false);
                      }}
                      className="absolute right-[24px] cursor-pointer"
                    >
                      <button className="text-cPrimary hover:text-cWhite border-[1.5px] border-cPrimary hover:bg-cPrimary rounded-lg text-[16px] py-2 px-5 text-center w-[200px] font-semibold">
                        Request Details
                      </button>
                    </div>
                  </Link>
                ) : (
                  ""
                )}

                {/* StopDetails => UiD: {id} */}
                <div className="capitalize text-2xl font-semibold pb-[20px]">
                  Stop Details
                </div>
                {/* <span className="text-2xl font-bold bg-cPrimary text-cWhite px-10 py-3">custom?? {targetStop?.isCustom}</span> */}
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">Receiver Name</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px]">
                      {targetStop.title}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">Delivery Date</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px]">
                      {formatDate(targetStop?.date)}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">Delivery Time</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px]">
                      {formatDateOrTime(
                        targetStop?.start_time + targetStop?.date
                      ) +
                        " ( Avg - " +
                        timeDiffHours(
                          targetStop?.end_time,
                          targetStop?.start_time,
                          targetStop?.date
                        ) +
                        " hr )"}
                      {/* {targetStop.start_time - targetStop.end_time} */}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">Delivery Address</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px]">
                      {targetStop.address}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">Total Products</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div
                      onClick={() => {
                        setShowProductListModal(true);
                      }}
                      className="text-left pl-[40px] gs-text-link-color cursor-pointer font-semibold"
                    >
                      {targetStop?.products ? targetStop.products?.length : "0"}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between item-center gs-text-body pb-[15px]">
                  <div className="w-1/2 ">Comment</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    <div className="text-left pl-[40px] truncate">
                      {targetStop?.comment ? targetStop?.comment : "NA"}
                    </div>
                  </div>
                </div>

                <div className="flex justify-between item-center gs-text-body">
                  <div className="w-1/2 ">Attachment</div>
                  <div className="flex justify-start item-center w-1/2 ">
                    <div>:</div>
                    {targetStop?.attachment ? (
                      <div className="text-left ml-[40px] w-[300px] h-[250px] overflow-hidden rounded-[5px]">
                        <>
                          <CommonImage
                            onClick={() => setShowImagePreview(true)}
                            src={targetStop?.attachment}
                            dummyImage={NoImageIcon}
                            alt="attachment"
                            className="w-full h-full object-cover cursor-pointer select-none"
                          />
                        </>
                      </div>
                    ) : (
                      <div className="ml-[40px] text-left">NA</div>
                    )}
                  </div>
                </div>

                {targetStop?.status === "ongoing" ? (
                  ""
                ) : targetStop?.isCustom ? (
                  <div className="pt-5">
                    <CommonButton
                      btnLabel={"Delete"}
                      colorType="danger"
                      onClick={() => setShowModal(true)}
                    />
                  </div>
                ) : (
                  ""
                )}

                {/* review: show if only ongoing */}
                {isShiftDetailsData?.on_going_stop ? (
                  <>
                    <div className="flex justify-between items-center pt-[40px]">
                      <div
                        onClick={() => setShowIncompleteDeliveryModal(true)}
                        className="gs-list-icon-btn"
                      >
                        Not Delivered
                      </div>
                      <div
                        className="gs-list-icon-btn"
                        onClick={() => setShowHoldModal(true)}
                      >
                        On Hold
                      </div>
                    </div>
                    <div className="pt-10 w-full">
                      <div
                        onClick={() => setShowDeliveryModal(true)}
                        className="gs-full-size-secondary-btn"
                      >
                        Complete
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            // hb:  if no item is selected
            <div className="card">
              <div className="flex justify-center items-center font-bold italic text-slate-400 min-h-[350px]">
                Select a stop to see details
              </div>
            </div>
          )}
        </div>

        {/* hb image preview modal */}
        <Modal
          show_modal={showImagePreview}
          setShowModal={setShowImagePreview}
          full_content={
            <div className="inline-block min-w-3xl px-5 py-5 my-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-[10px]">
              <div className="">
                <div className="relative">
                  <AiFillCloseCircle
                    onClick={() => setShowImagePreview(false)}
                    className="absolute top-0 right-0 text-2xl cursor-pointer select-none text-cRed z-20"
                  />
                  <div className="w-auto h-[80vh] rounded-[10px] overflow-hidden">
                    <CommonImage
                      src={targetStop?.attachment}
                      dummyImage={NoImageIcon}
                      alt="attachment"
                      className="p-0 w-full h-full object-contain"
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />

        {/* hr delete modal */}
        <CommonModal
          showModal={showModal}
          setShowModal={setShowModal}
          mainContent={
            <div>
              <div>
                <div className="relative">
                  <div className="pb-5 text-center font-bold text-xl">
                    Confirmation
                  </div>
                  <div className="pb-5 text-center text-fs16">
                    Are you sure you want to delete this stop?
                  </div>

                  <div className="flex flex-row justify-center">
                    <CommonButton
                      btnLabel={"Yes"}
                      onClick={() => {
                        deleteCustomStop(targetStop.stop_id);
                        setShowModal(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};

export default StopDetailsSp;
