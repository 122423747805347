// export const BaseUrlSrc = "https://stg-api.limadi.dk/";
// export const BaseUrlSrc = "http://limadi-api.genieinfo.tech/";
// export const BaseUrlSrc = "https://limadi-api.atiar.info/";
// export const BaseUrlSrc = "https://dev-api.limadi.dk/";
// export const BaseUrlSrc = "https://stg-api.limadi.dk/";


export const BaseUrlSrc = process.env.REACT_APP_BASE_URL;

const BaseUrl = BaseUrlSrc + 'api/v1';
export default BaseUrl;

// ! Auth Urls
export const auth_user_url = "/auth/user";
export const login_url = "/auth/login";
export const register_url = "/auth/register";
export const kuResetPassword = "/admin/user/change-password";
export const kuExpressTokenUrl = "/admin/user/user-access-request";

// B: Dashboard Urls
export const kuDashboardCards = "/admin/dashboard";
export const kuDashboardLicense = "/admin/dashboard/car-filter";
export const kuDashboardMessage = "/common/get-contact-limadi";

// b: dashboard top part details
export const kuCustomerLow = "/admin/user/customer-low-activity";
export const kuCompanyLow = "/admin/user/company-low-activity";
export const kuReqNoBid = "/admin/user/request-no-bid";
export const kuReqNoBidAction = "/admin/user/request-no-bid-action";
export const kuReqOnGoing = "/admin/user/request-ongoing-more";

//? route planner links
export const kuRoutePlannerList = "/route/get-list";
export const kuRouteCalculate = "/route/calculate";
export const kuAddCustomStop = "/custom-stop/add";
export const kuDeleteCustomStop = "/custom-stop/remove";

//? track online
export const kuTrackOnlineList = "/track-online/index";

// ? Track-Online :: Shift actions
export const kuShiftAction = "/shift/action";
export const kuRouteAction = "/route/action";

//? Shift planner
export const kuShiftPlannerList = "/shift/web/index";
export const kuDriverShiftPlannerList = "/driver/shifts/get-list";
export const kuShiftPlannerDetails = "/shift/show";
export const kuDriverShiftPlannerDetails = "/driver/shifts/show";
export const kuAddShiftPlanner = "/shift/add";
export const kuShiftCarsDriversList = "/shift/get-cars-and-drivers";
export const kuGetAllCarsList = "/shift/get-all-cars-and-drivers-web";
export const kuUpdateShiftPlanner = "/shift/update";
export const kuDeleteShiftPlanner = "/shift/delete";
export const kuFastCalculation = "/route-distance-calculate"; // request id required
export const kuAdvanceCalculation = "/request/advance-calculate"; // request id & shift id required


// ? Favorite Company
export const kuGetFavoriteCompany = "/request/favorite-company";
export const kuGetNotFavoriteCompany = "/favorite/search-company";
export const kuToggleFavCompany = "/favorite/toggle";
export const kuGetCompanyDetails = "/favorite/company-details";
export const kuGetCompanyReviewInfo = "/general/rating-and-review/company";

//* info:: car management
export const kuAllCar = "/company/car-management/all-cars";
export const kuAddCar = "/company/car-management/add-car";
export const kuUpdateCar = "/company/car-management/update-car";
export const kuDeleteCar = "/company/car-management/delete-car";
export const kuCarDeleteRequirement = "/common/get-car-delete-requirement";
export const kuGetCarLicense = "/admin/license/?flag=user";
export const kuRenewCarLicense = "/company/car-management/renew-car-license";

//* green :: driver management
export const kuDriverList = "/company/driver-invitation";
export const kuAddDriver = "/company/driver-invitation/add/xxy";
export const kuEditDriver = "/company/driver-invitation/update/xxy";
export const kuDeleteDriver = "/company/driver-invitation/delete/xxy";

// ! Request Urls
export const get_init_data = "/request/get-init-data";
export const search_company = "/request/search-company";
export const request_index = "/request/index";
export const request_show = "/request/show";
export const request_save = "/request/save";
export const request_delete = "/request/delete";
export const kuCancelRequestInvitation = "/request/invitation/decline";
export const kuUpdateRequestInvitationBidding = "/request/invitation/bidding/edit";
export const kuDeleteRequestInvitationBidding = "/request/invitation/bidding/cancel";
export const kuNotPlannedShiftAssign = "/request/invitation/bidding/assign-shift";
export const kuRequestAcknowledge = "/request/acknowledge";

// ! Company Urls
export const company_details = "/favorite/company-details";

// ! contact us / message URL
export const kuGetMessage = "/common/get-contact-limadi";
export const kuReplyMessage = "/common/contact-limadi-reply";
export const kuAdvancedFilterMessage = "/common/get-contact-limadi";
export const kuMessageSeenToggle = "/common/contact-limadi-seen";
export const kuWebMessage = "/common/contact-guest-index";
export const kuWebMessageSeen = "/common/contact-limadi-unseen";

// ! Settings URL
//? settings page[push notification, email notification,
//? change password, language, terms and conditions, faq]
export const kuChangePassword = "/common/settings/change-password";
export const kuGetFaq = "/common/get-faq";
export const kuGetToggleEmailPushNotificationState = "/common/settings/get-notification-toggle-state";
export const kuToggleEmailPushNotification = "/common/settings/notification-toggle";
export const kuContactLimadi = "/common/contact-limadi";
export const kuSetLanguage = "/common/set-language";
export const kuGetTermsAndCondition = "/common/get-terms-gdpr";
export const kuUpdateTermsAndCondition = "/common/update-term-gdpr";
export const kuAddTermsAndCondition = "/common/add-term-gdpr";

//! Admin FAQ

export const kuGetAllFAQ = "/admin/faq/get-all-faq";
export const kuAddFAQ = "/admin/faq/add-faq";
export const kuDeleteFAQ = "/admin/faq/delete-faq";
export const kuUpdateFAQ = "/admin/faq/update-faq";
export const kuToggleFAQ = "/admin/faq/toggle-faq";

// license 
export const kuGetLicense = "/admin/license/all-cars";
export const kuLicenseAction = "/admin/license/license-action";
export const kuGetLicenseAll = "/admin/license";
export const kuGetLicenseDetails = "/admin/license/car-details";
export const kuAddLicense = "/admin/license/add";
export const kuUpdateLicense = "/admin/license/update";
export const kuDeleteLicense = "/admin/license/toggle-status";
export const kuSearchCarLicenseApplication = "/admin/license/search-application";

// users
export const kuGetAllCustomers = "/admin/user/customer";
export const kuGetAllDrivers = "/admin/user/driver";
export const kuGetAllCompanies = "/admin/user/company";
export const kuSearchUser = "/admin/user/search";
export const kuAddUser = "/admin/user/add-user";


// banners
export const kuGetBanners = "/admin/banner/index";
export const kuDeleteBanner = "/admin/banner/delete";
export const kuAddBanner = "/admin/banner/create";
export const kuUpdateBanner = "/admin/banner/update";

// license package
export const kuGetPackage = "/admin/license/";
export const kuDeletePackage = "/admin/license/toggle-status";
export const kuAddPackage = "/admin/license/add";
export const kuUpdatePackage = "/admin/license/update";


// Customers
export const kuGetCustomers = "/admin/user/customer";
export const kuToggleStatus = "/admin/user/toggle-status";
// Drivers
export const kuGetDrivers = "/admin/user/driver";
// Company
export const kuGetCompany = "/admin/user/company";

// Rating
export const kuGetRating = "/general/rating-and-review/all";

export const kuSetDeviceToken = "/common/set-device-token";

// notifications
export const kuGetNotification = "/common/notification";
export const kuNotificationSeen = "/common/notification/show";

// cloud / global request
export const kuSubmitRequestInvitationBidding = "/request/invitation/bidding/add";
export const kuGlobalRequestIndex = "/company/cloud-request/request-search";

//? Shift planner
export const kuGetFilterList = "/company/filter-management/get-filters";
export const kuAddFilter = "/company/filter-management/add";
export const kuUpdateFilter = "/company/filter-management/update";
export const kuDeleteFilter = "/company/filter-management/delete";
export const kuSearchFilterList = "/company/cloud-request/request-search";
export const kuShiftSummeryData = "/request/show";

// favorite address
export const kuGetFavoriteAddress = "/common/get-fav-address";
export const kuAddFavoriteAddress = "/common/add-fav-address";
export const kuUpdateFavoriteAddress = "/common/update-fav-address";
export const kuDeleteFavoriteAddress = "/common/remove-fav-address";

// user details
//export const kuGetCommonDetails = "/admin/user/details";
export const kuGetCommonDetails = "/common/profile/get";
export const kuGetCommonUpdate = "/common/profile/update";
export const kuCommonDeleteUser = "/common/profile/delete ";


export const kuGetProfileDetails = "/common/profile/get";
export const kuUpdateProfile = "/common/profile/update";

export const kuGetRequestsNew = "/web/request/index";
export const kuRequestDetails = "/request/show";
export const kuRequestCount = "/admin/request/count";

export const kuGlobalRequestTableIndex = "/company/global-request/web/request-search";
export const kuShiftManagerTableData = "/shift/web/index";
export const kuGetShiftRouteList = "/route/get-list";
export const kuRatingAndReview = "/general/rating-and-review/company";


//chat
export const kuChatList = "/chat/list";
export const kuUserList = "/chat/admin/user-list";
export const kuSendMessage = "/chat/send-message";
export const kuGetMessageList = "/chat/get-message";
export const kuDeleteMessage = "/chat/delete";
export const chatCounterUrl = "/chat/total-unread-message";

//transportation types
export const kuGetTransportationTypes = "/admin/transportation-types";
export const kuAddTransportationType = "/admin/transportation-types";
export const kuUpdateTransportationType = "/admin/transportation-types";