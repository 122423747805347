import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CommonImage from "../../../Components/Image/CommonImage";
import useRequestStore from "../../../App/stores/RequestStore";
import { k_data_set } from "../../../App/Utility/const";

const CompanyTableRow = ({ data }) => {

  const navigateTo = useNavigate();

  const { setRequestActiveSection,setRequestDetails,setRequestSearchKey } = useRequestStore();

  return (
    <tr onClick={() => {
      setRequestActiveSection(k_data_set.saved);
      setRequestDetails({});
      setRequestSearchKey("")
      navigateTo("/users/companies/details/" + data?.id);
      }} className={`border-b-[1px] border-collapse border-cNmSelect  cursor-pointer hover:bg-cGridView select-none "font-semibold"`}>

      <td className='border-r-[1px] p-s10 text-left max-w-[180px] min-w-[180px] truncate'>
        <div className='flex items-center'>
          <div className='mx-s5 max-w-[40px] min-w-[40px]'>
            <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.image} alt="" />
          </div>
          {data?.name ? <Tooltip title={data?.name}><div className='capitalize items-center pl-s10 truncate'>{data?.name}</div></Tooltip> : 'NA'}
        </div>
      </td>

      <td className='p-s10 border-r-[1px] text-center px-s10min-w-[140px] max-w-[140px] truncate'>
        {data?.email ? <Tooltip title={data?.email}><div className='truncate'>{data?.email}</div></Tooltip> : 'NA'}
      </td>

      <td className='p-s10 border-r-[1px] text-center min-w-[100px] max-w-[100px] truncate'>
        {data?.cvr ?? 'NA'}
      </td>


      <td className='p-s10 border-r-[1px] px-s10 text-center min-w-[100px] max-w-[100px] truncate'>
        {data?.phone ? <Tooltip title={data?.phone}><div className='truncate'>{data?.phone}</div></Tooltip> : 'NA'}
      </td>

      <td className='p-s10 border-r-[1px] text-center text-fs14 font-fw600 min-w-[70px] max-w-[70px]'>
        {data?.is_active ?
          <span className='text-cSecondary'>Active</span>
          :
          <span className='text-cRed'>Inactive</span>
        }
      </td>
    </tr>
  )
}


export default CompanyTableRow;