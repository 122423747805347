import axios from 'axios';
import i18next, { t } from 'i18next';
import create from 'zustand';
import { kuExpressTokenUrl, kuSetDeviceToken, kuSetLanguage } from '../../Utility/url';
import { Toastr } from '../../Utility/UtilityFunctions';

const useGeneralStore = create((set) => ({
  isLoading: false,
  setLoading: (isLoading) => set({ isLoading: isLoading }),

  isLoadingSearch: false,
  setIsLoadingSearch: (isLoading) => set({ isLoadingSearch: isLoading }),

  trackingUpload: false,
  setTrackingUpload: (trackingUpload) => set({ trackingUpload: trackingUpload }),

  app_lang_code: 'en',
  setAppLangCode: (langCode) => set((state) => ({ app_lang_code: langCode })),

  showImageViewerModal: false,
  setShowImageViewerModal: (data) => set((state) => ({ showImageViewerModal: data })),

  imageViewerModalUrl: null,
  setImageViewerModalUrl: (data) => set((state) => ({ imageViewerModalUrl: data })),

  numOfUnreadNotification: 0,
  setHasUnseenNotification: () => {
    let count = localStorage.getItem('numOfUnreadNotification');
    set({ numOfUnreadNotification: count ? parseInt(count) : 0 })
  },

  showImagePopup: false,
  setShowImagePopup: (value) => set({ showImagePopup: value }),

  showImagePreviewSRC: "",
  setShowImagePreviewSRC: (value) => set({ showImagePreviewSRC: value }),

  isOnline: false,
  setIsOnline: (value) => set({ isOnline: value }),
  // setIsOnline: (isOnline) => set((state) => {
  //   !state.isOnline && isOnline && Toastr(t("You Are Online"), 'success')
  //   state.isOnline && !isOnline && Toastr(t("You Are Offline"), 'warning')
  //   state.isOnline = isOnline
  // }),

  is_logged_out: false,
  setLoggedOut: (isLoggedOut) => set({ is_logged_out: isLoggedOut }),

  selectedItemSingle: { label: "", id: -1 },
  setSelectedItemSingle: (value) => set({ selectedItemSingle: value }),

  selectedItemMulti: [],
  setSelectedItemMulti: (value) => set({ selectedItemMulti: value }),

  selectedItemMultiString: "",
  setSelectedItemMultiString: (value) => set({ selectedItemMultiString: value }),

  is_app_initialized: false,
  setAppInitialized: (data) => set({ is_app_initialized: data }),

  is_user_loading: false,
  setUserLoading: (value) => set({ is_user_loading: value }),

}))




export const setAppLangCodeFoo = async (lang_code = 'en') => {
  const { setLoading } = useGeneralStore.getState()
  const { setAppLangCode } = useGeneralStore.getState()

  try {
    setLoading(true)

    const res = await axios.post(kuSetLanguage, { lang_code: lang_code });
    console.log("setAppLangCodeFoo: ", res.data);

    if (res.data.success) {
      setAppLangCode(lang_code)
      i18next.changeLanguage(lang_code)
      localStorage.setItem("lang_code", lang_code);
    }
    else Toastr(res.data.message);

    setLoading(false)
  } catch (err) {
    Toastr(t("An error occurred!"));
    setLoading(false)
    console.log('setAppLangCodeFoo: ', err);
  }
}

export const setFirebaseDeviceTokenFoo = async (token) => {
  try {
    const res = await axios.post(kuSetDeviceToken, { user_id: localStorage.getItem("user_id"), device_token: token });
    console.log("setFirebaseDeviceTokenFoo: ", res.data);

    if (res.data.success) {
      localStorage.setItem("limadi_admin_device_token", token);
    } else {
      // Toastr(res.data.message);
    }
  } catch (err) {
    console.log(err);
    // Toastr(t('An error occurred!'));;
  }
}


export const formatSearchAddressV2 = (data = [], has_postal_code = true) => {
  // console.log('formatSearchAddressV2', data);
  let temp_address = [];
  data.forEach((d) => {
    let x = d.address;
    let y = '';
    x?.street ? y += x.street + (x?.houseNumber ? ' ' : ', ') : y += '';
    x?.houseNumber ? y += x.houseNumber + ', ' : y += '';
    (has_postal_code && x?.postalCode) ? y += x.postalCode + ', ' : y += '';
    x?.city ? y += x.city + ', ' : y += '';
    x?.countryName ? y += x.countryName + ', ' : y += '';
    let count = y.split(',').length - 1;
    if (count > 0) y = y.substring(0, y.length - 2);
    (y.length !== 0) && temp_address.push({ ...d, title: y });
  })
  // console.log('temp_address', temp_address);
  return temp_address;
}


export const suggestionFormat = (x) => {
  // console.log('suggestionFormat', x);
  // console.log('suggestionFormat', x.street);
  let y = '';
  x?.street ? y += x.street + (x?.houseNumber ? ' ' : ', ') : y += '';
  x?.houseNumber ? y += x.houseNumber + ', ' : y += '';
  x?.city ? y += x.city + ', ' : y += '';
  x?.countryName ? y += x.countryName + ', ' : y += '';
  (x?.postalCode) ? y += x.postalCode + ', ' : y += '';
  let count = y.split(',').length - 1;
  if (count > 0) y = y.substring(0, y.length - 2);
  return y;
}

export default useGeneralStore;

//get express token             

export const getExpressToken = async (form_data) => {
  const { setLoading } = useGeneralStore.getState();

  try {
    setLoading(true)

    const res = await axios.get(kuExpressTokenUrl, { params: form_data });
    console.log("getExpressToken: ", res.data);

    if (res.data.success) {
      setLoading(false);
      return res.data.data.token;
    }
    else {
      Toastr(res.data.message);
      setLoading(false);
      return false;
    }
  } catch (err) {
    Toastr(t("An error occurred!"));
    console.log('getExpressToken: ', err);
    setLoading(false);
    return false;
  }
}
