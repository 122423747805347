/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useRequestStore, { getRequestDetails, getRequestsData } from '../../../../App/stores/RequestStore';
import RequestItem from './components/RequestItem';
import EmptyMessage from '../../../../Components/EmptyMessage';
import RequestDetails from './RequestDetails';
import { useDebounce } from 'use-debounce';
import LimadiSearchBox from '../../../../Components/Input/LimadiSearchBox';
import { k_data_set } from '../../../../App/Utility/const';

const Content = () => {
    const { t } = useTranslation();
    const { customer_id, company_id } = useParams();
    const { request_list, setRequestDetails, request_details, setSavedIndex, savedIndex, request_active_section, setRequestSearchKey, request_search_key } = useRequestStore();

    const [searchValue] = useDebounce(request_search_key, 500);

    // useEffect(() => {
    //     if (request_search_key) {
    //         if (customer_id) getRequestsData(request_active_section === k_data_set.not_planned ? k_data_set.awarded : request_active_section, customer_id, request_search_key);
    //         else if (company_id) getRequestsData(request_active_section === k_data_set.not_planned ? k_data_set.awarded : request_active_section, company_id, request_search_key);
    //     }
    //     else {
    //         if (customer_id) getRequestsData(request_active_section === k_data_set.not_planned ? k_data_set.awarded : request_active_section, customer_id, "");
    //         else if (company_id) getRequestsData(request_active_section === k_data_set.not_planned ? k_data_set.awarded : request_active_section, company_id, "");
    //     }
    // }, [customer_id, company_id, request_active_section])

    useEffect(() => {
        if (customer_id) getRequestsData(request_active_section === k_data_set.awarded  ? k_data_set.awarded_admin : request_active_section, customer_id, request_search_key);
        else if (company_id) getRequestsData(request_active_section === k_data_set.awarded  ? k_data_set.awarded_admin : request_active_section, company_id, request_search_key);
    }, [customer_id, company_id, request_active_section])


    useEffect(() => {
        setRequestDetails({})
    }, [])

    useEffect(() => {
        if (request_search_key) {
            if (customer_id) getRequestsData(request_active_section === k_data_set.not_planned ? k_data_set.awarded : request_active_section, customer_id, searchValue);
            else if (company_id) getRequestsData(request_active_section === k_data_set.not_planned ? k_data_set.awarded : request_active_section, company_id, searchValue);
        } else {
            if (customer_id) getRequestsData(request_active_section === k_data_set.not_planned ? k_data_set.awarded : request_active_section, customer_id, "");
            else if (company_id) getRequestsData(request_active_section === k_data_set.not_planned ? k_data_set.awarded : request_active_section, company_id, "");
        }
    }, [searchValue])



    return (
        <div className="grid grid-cols-12 gap-2 md:gap-8 2xl:gap-12">

            {/* favorite address list */}
            <div className="col-span-12 mt-5 bg-white shadow rounded-xl lg:col-span-4">
                <div className="flex-col justify-start">
                    {/* search bar */}
                    <div className='self-end px-5 py-5'>
                        <LimadiSearchBox
                            fullWidth={true}
                            placeholder={t('search')}
                            type="search"
                            onChange={(e) => { setRequestSearchKey(e.target.value) }}
                            name="searchKey"
                            value={request_search_key}
                            withClearSearch={true}
                            onSearchClear={() => {
                                setRequestSearchKey("");
                            }}
                        />
                    </div>

                    <ul className='overflow-y-auto h-screen scroll-smooth'>
                        {
                            request_list?.length > 0 ?
                                request_list?.map((item, index) => (
                                    <RequestItem
                                        title={item?.title ?? "NA"}
                                        description={
                                            request_active_section === k_data_set.history ||
                                                request_active_section === k_data_set.ongoing ||
                                                request_active_section === k_data_set.completed
                                                ?
                                                `${item?.stops_complete_count ?? 0} of ${item?.stops_count ?? 0} / ${item?.products_complete_count ?? 0} of ${item?.products_count ?? 0}`
                                                : (item?.stops_count ?? 0) + '/' + (item?.products_count ?? 0)
                                        }
                                        key={index}
                                        index={index}
                                        isSelected={savedIndex === item?.id ? true : false}
                                        onSelect={() => {
                                            setSavedIndex(item?.id);
                                            if (customer_id) {
                                                getRequestDetails(request_active_section, item?.id, "customer", customer_id);
                                            } else if (company_id) {
                                                getRequestDetails(request_active_section, item?.id, "company", company_id);
                                            }

                                        }}
                                    />
                                ))
                                : <EmptyMessage />
                        }
                    </ul>
                </div>

            </div>

            {/* request details */}
            <div className="col-span-12 mt-5 lg:col-span-8">
                {
                    request_details?.id ? <RequestDetails />
                        :
                        <div className="card h-[30rem] flex justify-center text-center text-gray-500 font-bold italic text-lg">
                            <EmptyMessage message={t('Please select a Request to see details.')} />
                        </div>
                }
            </div>
        </div>
    );
};

export default Content;