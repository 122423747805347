/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useDashboardStore, { getDashboardTop, searchDashboardTop } from '../../App/stores/DashboardStore';
import TitleBar from '../../Components/Common/TitleBar';
import BackLinks from '../../Components/Layout/BackLinks';
import CommonTable from '../../Components/Table/CommonTable';
// import NoDataRow from '../../Components/Table/NoDataRow';
import LowCompanyRow from './TableRows/LowCompanyRow';
import LowCustomerRow from './TableRows/LowCustomerRow';
import NoBidActionRow from './TableRows/NoBidActionRow';
import OnGoingRow from './TableRows/OnGoingRow';
import ReqNoBidRow from './TableRows/ReqNoBidRow';

function DashRequestDetails() {
    const { dashboardTopTableData, searchLoading, searchKey, setSearchKey } = useDashboardStore();

    const [tableData, setTableData] = useState({
        header: [],
        title: "",
        onSearchFn: () => { },
        rowComponent: <></>
    });


    const { details_type } = useParams();

    const noBidHeader = [
        { index: 1, name: ("#") },
        { index: 2, name: ("Name") },
        { index: 3, name: ("Req. Title") },
        { index: 4, name: ("Request Date") },
        { index: 5, name: ("Expire Date") },
        { index: 6, name: ("Request Type") },
    ];


    const noResponseHeader = [
        { index: 1, name: ("#") },
        { index: 2, name: ("Name") },
        { index: 3, name: ("Request Name") },
        { index: 4, name: ("email") },
        { index: 5, name: ("user type") },
        { index: 6, name: ("Last Action") },
        { index: 7, name: ("Total Bid") },
    ];


    const reqOngoingHeader = [
        { index: 1, name: ("#") },
        { index: 2, name: ("creator") },
        { index: 3, name: ("Request Title") },
        { index: 4, name: ("request type") },
        { index: 5, name: ("company") },
        { index: 6, name: ("driver") },
        { index: 7, name: ("pickup date") },
    ];

    const lowCustHeader = [
        { index: 1, name: ("#") },
        { index: 2, name: ("Name") },
        { index: 3, name: ("email") },
        { index: 4, name: ("user type") },
        { index: 5, name: ("total cancel req.") },
        { index: 6, name: ("Last cancel") },
        { index: 7, name: ("status") },
    ];

    const lowCompHeader = [
        { index: 1, name: ("#") },
        { index: 2, name: ("Name") },
        { index: 3, name: ("email") },
        { index: 4, name: ("CVR") },
        { index: 5, name: ("Total Cancel Req.") },
        { index: 6, name: ("Last Cancel") },
        { index: 7, name: ("Status") },
    ];

    const HandleTableData = async (type) => {

        switch (type) {
            case "req_no_bid":
                setTableData({
                    header: noBidHeader,
                    title: "Request with no bid",
                    rowComponent: dashboardTopTableData?.data?.map((item, index) => <ReqNoBidRow data={item} num={index + 1} key={index} />),
                })
                break;


            case "customer_no_response":
                setTableData({
                    header: noResponseHeader,
                    title: "Customer not responding",
                    rowComponent: dashboardTopTableData?.data?.map((item, index) => <NoBidActionRow data={item} num={index + 1} key={index} />),
                })
                break;

            case "req_ongoing":
                setTableData({
                    header: reqOngoingHeader,
                    title: "More than 2 days in Ongoing",
                    rowComponent: dashboardTopTableData?.data?.map((item, index) => <OnGoingRow data={item} num={index + 1} key={index} />),
                })
                break;

            case "customer_low_activity":
                setTableData({
                    header: lowCustHeader,
                    title: "Customer with low activity",
                    rowComponent: dashboardTopTableData?.data?.map((item, index) => <LowCustomerRow data={item} num={index + 1} key={index} />),
                })
                break;

            case "company_low_activity":
                setTableData({
                    header: lowCompHeader,
                    title: "Company with low activity",
                    // onSearchFn: 
                    rowComponent: dashboardTopTableData?.data?.map((item, index) => <LowCompanyRow data={item} num={index + 1} key={index} />),
                })
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        console.log('details type:', details_type);
        getDashboardTop(details_type);
    }, [details_type]);

    useEffect(() => {
        // console.log("DATA", dashboardTopTableData);
        HandleTableData(details_type);
    }, [dashboardTopTableData, details_type])

    useEffect(() => {
        // console.log("UPDATE DATA", dashboardTopTableData);
        HandleTableData(details_type);
    }, [dashboardTopTableData]);

    useEffect(() => {
        // ! Using debounce
        const timer = setTimeout(() => {
            if (searchKey !== "") {
                searchDashboardTop(details_type, "", searchKey);
            } else {
                getDashboardTop(details_type);
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [searchKey])

    return (
        <div>
            <BackLinks
                linksArray={[
                    { label: "Dashboard", linkTo: "/" },
                    { label: tableData.title, linkTo: "" },
                ]}
            />
            <TitleBar
                count={dashboardTopTableData?.total}
                onClick={() => getDashboardTop(details_type)}
                label={tableData.title}
                rightCornerComponent={
                    <FilterOptions />
                }
            />

            <CommonTable
                headers={tableData.header}
                topRightComponent={<TakeItem />}

                searchValue={searchKey}
                searchOnChange={(e) => {
                    setSearchKey(e.target.value);
                    console.log(e.target.value);
                }}
                search_loading={searchLoading}

                showingFrom={dashboardTopTableData?.from}
                showingTo={dashboardTopTableData?.to}
                totalResult={dashboardTopTableData?.total}

                paginationObject={dashboardTopTableData}
                showPagination={dashboardTopTableData?.last_page !== 1 ? true : false}
                paginationOnClick={(url) => {
                    console.log("PAGINATION URL::: ", url);
                    searchKey !== "" ? searchDashboardTop(details_type, url, searchKey) : getDashboardTop(details_type, url);
                    // getDashboardTop(details_type, url);
                }}

                items={
                    <>
                        {tableData.rowComponent}
                        {/* todo: with no data check need to finish later */}
                        {/* {dashboardTopTableData?.data?.length > 0 ? tableData.rowComponent : <NoDataRow columnNumber={7} />} */}
                    </>
                }
            />
        </div>
    )
}

export default DashRequestDetails

const TakeItem = () => {
    const { setDashTopTakeAmount, dashTopTakeAmount } = useDashboardStore();
    const { details_type } = useParams();

    return (
        <div className=''>
            <span className='pr-s10'>Show</span>
            <select
                value={dashTopTakeAmount ? dashTopTakeAmount : 10}
                onChange={(e) => {
                    console.log(e.target.value);
                    setDashTopTakeAmount(e.target.value);
                    console.log(e.target.value);
                    getDashboardTop(details_type);
                }}
                className='pl-5 h-s30 rounded-br5 select-style space-y-s5'
            >
                <option
                    className='py-s10 text-cMainBlack'
                    selected={dashTopTakeAmount === 10}
                    value={10}
                >10</option>

                <option
                    className='py-s10 text-cMainBlack'
                    selected={dashTopTakeAmount === 25}
                    value={25}
                >25</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={dashTopTakeAmount === 50}
                    value={50}
                >50</option>
                <option
                    className='py-s10 text-cMainBlack'
                    selected={dashTopTakeAmount === 100}
                    value={100}
                >100</option>
            </select>   <span className='pl-s10'>Entries</span>

        </div>
    )
}

const FilterOptions = () => {
    const { setSearchKey } = useDashboardStore();
    const { details_type } = useParams();
    const navigateTo = useNavigate();
    return (
        <div>
            <select
                // value={dashTopTakeAmount ? dashTopTakeAmount : ""}
                onChange={(e) => {
                    navigateTo(e.target.value);

                    setSearchKey("");
                }}
                className='pl-5 h-s30 rounded-br5 select-style space-y-s5'
            >
                <option
                    className='py-s10 text-cMainBlack'
                    value={""}
                >Select an option</option>

                <option
                    className='py-s10 text-cMainBlack'
                    value={"/dash-request-details/customer_low_activity"}
                    selected={details_type === "customer_low_activity" ? true : false}
                >Customer with low profile</option>

                <option
                    className='py-s10 text-cMainBlack'
                    value={"/dash-request-details/company_low_activity"}
                    selected={details_type === "company_low_activity" ? true : false}
                >Company with low profile</option>

                <option
                    className='py-s10 text-cMainBlack'
                    value={"/dash-request-details/req_no_bid"}
                    selected={details_type === "req_no_bid" ? true : false}
                >Request with no bid</option>

                <option
                    className='py-s10 text-cMainBlack'
                    value={"/dash-request-details/customer_no_response"}
                    selected={details_type === "customer_no_response" ? true : false}
                >Customer is not responding</option>

                <option
                    className='py-s10 text-cMainBlack'
                    value={"/dash-request-details/req_ongoing"}
                    selected={details_type === "req_ongoing" ? true : false}
                >More than 2 days Ongoing</option>

            </select>
        </div>
    )
}