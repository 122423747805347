
export const k_data_set = {
    all_requests: 'all',
    saved: 'saved',
    invitation: 'invitations',
    in_bidding: 'in_bidding',
    not_planned: 'not_planned',
    awarded: 'awarded',
    awarded_admin: 'awarded_admin',
    ongoing: 'ongoing',
    completed: 'complete',
    history: 'history',
    global: 'global',
  }

  /** customer, company, sa */
export const role = {
  customer: "private",
  company: "company",
  admin: "sa",
  driver:"driver"
}

// pusherConfig.js
export const chatAppId = process.env.REACT_APP_CHAT_APP_ID;
export const chatKey = process.env.REACT_APP_CHAT_KEY;
export const chatSecret = process.env.REACT_APP_CHAT_SECRET;
export const chatCluster = process.env.REACT_APP_CHAT_CLUSTER;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;