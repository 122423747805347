/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useState } from 'react'
import Textarea from '@mui/joy/Textarea';
import { useEffect } from 'react'
import ChatMessageItem from './ChatMessageItem'
import { useRef } from 'react'
import NoMessage from './NoMessage'
import NoSelectChat from './NoSelectChat'
import { useTranslation } from 'react-i18next'
import SendImageView from './SendImageView'
import Pusher from 'pusher-js';
import { chatCluster, chatKey, role } from '../../../App/Utility/const'
import { Toastr, convertATime, countEmptySpaces, formatDate, isObjectEmpty } from '../../../Utility/UtilityFunctions'
import useChatStore, { getChatList, getMessageList, sendMessage } from '../../../App/stores/chatStore';
import { iChatCamera, iChatDelete, iSendMessage } from '../../../App/Utility/ImageImports';
import useGeneralStore from '../../../App/stores/GeneralStore';
import CommonImage from '../../../Components/Image/CommonImage';

const ChatRightBar = () => {

    const { setChatSearch,message_scroll_bottom, setMessage_scroll_bottom, setShowDeleChatModal, textFieldCount, setTextFieldCount, selectChat, message_array, setAddMessageArray, chat_search } = useChatStore();

    const { setLoading } = useGeneralStore.getState()

    const { t } = useTranslation();

    const currentDateTime = new Date();
    const chatContainerRef = useRef(null);
    const inputFile = useRef(null);

    const onButtonClick = () => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    };

    const [newMessage, setNewMessage] = useState('');

    const [paginationLoading, setPaginationLoading] = useState(true);

    const [image, setImage] = useState({
        attachment: null,
        id: null,
    });

    // image upload onChange function
    const onChangeFile = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        var reader = new FileReader();

        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

        if (!allowedTypes.includes(file.type)) {
            // Do not display any error message or take any action
            return;
        }

        reader.onloadend = async function () {
            await setImage({ id: selectChat.id, attachment: reader.result });
        };
        reader.onload = async (event) => {
            await setImage({ id: selectChat.id, attachment: reader.result });
            image.src = event.target.result;
        };
        if (!/^image\/(png|jpe?g|jpg|jpeg|gif)$/.test(file.type)) {
            Toastr({ message: t('Invalid file type. Only jpeg and png and jpg files are allowed.'), type: 'error' });
            return;
        }
        reader.readAsDataURL(file);
    };

    // input box onKeyPress
    const handleKeyPress = (e) => {
        if (textFieldCount === 4) {
            if (e.key === 'Enter') {
                e.preventDefault();
            }
        }
    };

    const noEmptySpace = (e) => {
        if (e.key === 'Enter' && e.target.value.trim() === "") {
            e.preventDefault();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newMessage & !image?.attachment) { return; }

        if (countEmptySpaces(newMessage) > 0) {
            setNewMessage('');
            return;
        }

        const messageValue = newMessage;
        const imageValue = image?.attachment;
        const messageData = {
            take: message_array?.total ? message_array?.total + 1 : 30,
            to_id: selectChat?.user_id,
            message: messageValue,
            attachment: imageValue,
            channel: selectChat?.channel_name,
            search:""
        };
        if (message_array?.data?.length > 0) {
            await setAddMessageArray({
                current_page: message_array?.current_page,
                last_page: message_array?.last_page,
                data: [
                    ...message_array?.data,
                    {
                        message: messageValue,
                        attachment: imageValue,
                        user_type: role.admin,
                        to_is_read: false,
                        created_at: null,
                        fail: false,
                        new: true,
                    },
                ],
                total: message_array?.total + 1,
            });
        } else {
            await setAddMessageArray({
                current_page: message_array?.current_page,
                last_page: message_array?.last_page,
                data: [
                    {
                        message: messageValue,
                        attachment: imageValue,
                        user_type: role.admin,
                        to_is_read: false,
                        created_at: null,
                        fail: false,
                        new: true,
                    },
                ],
                total: message_array?.total,
            });
        }
        scrollBottom();
        setNewMessage('');
        await setImage({ id: null, attachment: null });
        setTextFieldCount(40);
        console.log("messageData", messageData);
        const success = await sendMessage(messageData);
        if (success?.success) {
            setPaginationLoading(false);
            await setAddMessageArray({
                current_page: success?.data?.current_page,
                last_page: success?.data?.last_page,
                data: success?.data?.data?.slice().reverse(),
                total: success?.data?.total
            });

           if(chat_search) setChatSearch("");
            await getChatList(chat_search, false, false);
        } else {
            console.log("fail")
            const errorMessage = message_array?.data.filter((i) => !i?.new);
            await setAddMessageArray({
                current_page: message_array?.current_page,
                last_page: message_array?.last_page,
                data: [...errorMessage,
                {
                    message: messageValue,
                    attachment: imageValue,
                    user_type: role.admin,
                    to_is_read: false,
                    created_at: new Date(),
                    fail: true,
                },
                ],
                total: message_array?.total,
            });
        }
    }


    useEffect(() => {
        setNewMessage("");
        if (selectChat?.user_type && selectChat?.id) {
            fetchData();
        } else { setAddMessageArray([]); }
        setTextFieldCount(40);
    }, [selectChat?.id, selectChat?.new])


    // fetch message list
    const fetchData = async (isLoading = true) => {
        if (isLoading && paginationLoading) setLoading(true)
        if (message_array?.total) {
            await getMessageList({ "chat_id": parseInt(selectChat?.id), take: message_array?.total + 1 }, false)
            scrollBottom();
        } else {
            const res = await getMessageList({ "chat_id": parseInt(selectChat?.id), take: 10 }, false);
            await getMessageList({ "chat_id": parseInt(selectChat?.id), take: res?.data?.total + 1 }, false);
        }
        if (isLoading) setLoading(false)
    }

    // pusher set
    useEffect(() => {
        let total = message_array?.total + 1;
        const pusher = new Pusher(chatKey, { cluster: chatCluster });

        const channel = pusher.subscribe(selectChat?.channel_name + "-" + selectChat?.id);
        channel.bind('my-event', async (data) => {
            console.log("pusher data", data)
            if (data?.message?.chat_id && message_array?.total) {
                const success = await getMessageList({ "chat_id": data?.message?.chat_id, take: total }, false);
                await setAddMessageArray({
                    current_page: success?.data?.current_page,
                    last_page: success?.data?.last_page,
                    data: success?.data?.data?.slice().reverse(),
                    total: success?.data?.total
                });
                // scrollBottom();
                await getChatList(chat_search, false, false);
                total++;

            } else {
                message_array?.map((item, index) => (
                    message_array.data[index].to_is_read = 1
                ))
            }
        });

        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        };

    }, [selectChat?.id]);


    useEffect(() => {
        scrollBottom();
    }, [message_scroll_bottom])

    const scrollBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
        setMessage_scroll_bottom(false)
    }

    // input box increment row function
    const calculateRowCount = async (e) => {
        const lineBreaks = await (e.match(/\n/g) || [])?.length;

        if (lineBreaks < 136) {
            await setTextFieldCount(
                (lineBreaks === 0 && 40) ||
                (lineBreaks === 1 && 64) ||
                (lineBreaks === 2 && 88) ||
                (lineBreaks === 3 && 112) ||
                (lineBreaks === 4 && 136) ||
                (lineBreaks >= 5 && 136) 
            );
        }
    };

    const textAreaRef = useRef(null);


    const calculateDynamicHeight = (clientHeight) => {
        if (clientHeight === 40) {
            return 'calc(100vh - 342px)';
        } else if (clientHeight === 64) {
            return 'calc(100vh - 366px)';
        } else if (clientHeight === 88) {
            return 'calc(100vh - 390px)';
        } else if (clientHeight === 112) {
            return 'calc(100vh - 414px)';
        } else if (clientHeight === 136) {
            return 'calc(100vh - 438px)';
        }
        else {
            // Default height if none of the specified conditions match
            return 'calc(100vh - 342px)';
        }
    };

    useEffect(() => {
        // textFieldCount
        setTextFieldCount(textAreaRef?.current?.clientHeight)
    }, [textAreaRef?.current?.clientHeight]);

    return (
        <div>
            {!isObjectEmpty(selectChat) ?
                <div className='flex flex-col justify-between h-full'>
                    <div className='flex items-center justify-between px-5 py-3 pb-2 border-b-2 border-cLightGrayishBlue'>
                        <div className="flex space-x-2">
                            <CommonImage alt="" src={selectChat?.image} className="max-w-[44px] min-w-[44px] h-[44px] rounded-full" />
                            <div>
                                <div className="capitalize text-cMainBlack font-fw600 text-fs16">{selectChat?.name}</div>
                                <div className="capitalize text-cMainBlack font-fw400 text-fs12 ">
                                    {selectChat?.user_type}
                                </div>
                            </div>
                        </div>
                        {(selectChat?.id && message_array?.data?.length > 0) && <img onClick={() => { setShowDeleChatModal(true) }} src={iChatDelete} alt='' className='w-5 h-5 cursor-pointer' />}
                    </div>

                    {
                        image?.attachment && image?.id === selectChat?.id ? (
                            <SendImageView
                                textFieldCount={calculateDynamicHeight(textFieldCount ?? 40)}
                                image={image?.attachment}
                                clearImage={async () => await setImage({ id: null, attachment: null })}
                            />)
                            :
                            <div>
                                {
                                    message_array?.data?.length > 0 ?
                                        <div
                                            style={{ height: calculateDynamicHeight(textFieldCount ?? 40) }}
                                            ref={chatContainerRef}
                                            className="flex flex-col-reverse py-5 overflow-y-auto">
                                            <div className='px-5 space-y-5'>
                                                {
                                                    message_array?.data?.map((item, index) => (
                                                        <ChatMessageItem
                                                            fail={item?.fail ?? false}
                                                            key={index}
                                                            newMsg={item?.new ?? false}
                                                            attachment={item?.attachment}
                                                            messageText={item?.message}
                                                            isReplyType={item?.user_type === role.admin}
                                                            isReed={item?.to_is_read ? true : false}
                                                            time={
                                                                item?.created_at ?
                                                                    new Date(item?.created_at)?.toDateString() === currentDateTime.toDateString() ?
                                                                        convertATime(item?.created_at) :
                                                                        `${formatDate(item?.created_at)}, ${convertATime(item?.created_at)}` : null
                                                            }
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div> :
                                        <NoMessage textFieldCount={calculateDynamicHeight(textFieldCount ?? 40)} />
                                }
                            </div>
                    }

                    {
                        selectChat?.is_active ?
                            <div className='px-5 pt-[18px] border-t-2 border-cLightGrayishBlue'>
                                <form onSubmit={handleSubmit}>
                                    <div className={`flex  ${textFieldCount === 0 ? "items-center" : "justify-end items-end"} space-x-4`}>
                                        <div className="relative w-full">
                                            <Textarea
                                                ref={textAreaRef}
                                                minRows={1}
                                                maxRows={5}
                                                maxLength={255}
                                                minLength={0}
                                                placeholder='Message'
                                                value={newMessage}

                                                onChange={async (e) => {
                                                    if (e.target.value.length <= 1024) {
                                                        if (countEmptySpaces(e.target.value) > 0) {
                                                            setNewMessage('');
                                                        } else {
                                                            setNewMessage(e.target.value);
                                                            if ((e.target.value.match(/\n/g) || [])?.length > 0) {
                                                                calculateRowCount(e.target.value)
                                                            }
                                                            if (e.target.value.length < 30) {
                                                                setTextFieldCount(40);
                                                            }
                                                        }
                                                    }
                                                }}

                                                onKeyDown={async (e) => {
                                                    if (e.key === 'Enter' && e.shiftKey) {
                                                        await handleKeyPress();
                                                    } else if (e.key === 'Enter') {
                                                        await handleSubmit(e);
                                                    }
                                                }}

                                                onKeyPress={noEmptySpace}

                                                style={{
                                                    paddingTop: '8px',
                                                    paddingBottom: '8px',
                                                    paddingLeft: '8px',
                                                    paddingRight: '25px',
                                                    borderWidth: '2px',
                                                    borderStyle: 'solid',
                                                    borderColor: '#2257AA',
                                                }}

                                                sx={{
                                                    '&::before': {
                                                        display: 'none',
                                                    },
                                                    '&:focus-within': {
                                                        outline: '0px',
                                                        outlineOffset: '0px',
                                                    },
                                                }}
                                            />
                                            {
                                                !image?.attachment &&
                                                <div>
                                                    <input
                                                        accept=".png, .jpeg, .jpg"
                                                        onChange={onChangeFile.bind(this)}
                                                        onClick={() => (inputFile.current.value = null)}
                                                        type="file"
                                                        id="file"
                                                        ref={inputFile}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <img onClick={onButtonClick} src={iChatCamera} alt=""
                                                        className={`absolute ml-3 cursor-pointer ${textFieldCount === 40 ? "right-3 top-1/4" : `bottom-3 ${textFieldCount === 136 ? "right-[5px]" : "right-3"}`}`}
                                                    />
                                                </div>
                                            }
                                        </div>
                                        {
                                            !newMessage & !image?.attachment ? (
                                                <button className="outline-none flex items-center justify-center rounded-full cursor-not-allowed bg-[#A8A8A8] min-h-[44px] max-h-[44px] max-w[44px] min-w-[44px]">
                                                    <img src={iSendMessage} alt="" className="" />
                                                </button>
                                            ) : (
                                                <button type="submit" className="outline-none flex items-center justify-center rounded-full cursor-pointer bg-cBrand min-h-[44px] max-h-[44px] max-w[44px] min-w-[44px]">
                                                    <img src={iSendMessage} alt="" className="" />
                                                </button>
                                            )
                                        }
                                    </div>
                                </form>
                            </div>
                            :
                            <div className="border-t-[1px] border-cLightGrayishBlue text-center flex items-center justify-center">
                                <div className="flex items-center justify-center text-center mt-7 text-fs16 text-[#333333] font-fw400">
                                    The chat is no longer active
                                </div>
                            </div>
                    }
                </div>
                : <NoSelectChat />
            }
        </div>
    )
}

export default ChatRightBar