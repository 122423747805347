/* eslint-disable react-hooks/exhaustive-deps */
import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import Accordion from "../../../Components/Accordion";
import AddFAQModal from "./AddFAQModal";
import { DeleteFAQModal } from "./DeleteFAQModal";
import { UpdateFAQModal } from "./UpdateFAQModal";
import { alpha, styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { DeleteIcon, EditIcon } from "../../../App/Utility/ImageImports";
import useSettingsStore, { getAllFAQ, handleFAQToggle } from "../../../App/stores/SettingsStore";
import CommonButtonOutlined from "../../../Components/Button/CommonButtonOutlined";
import { PageTitle } from "../../../Utility/UtilityFunctions";
import NA from "../../../Components/NA";
import Tooltip from "antd/es/tooltip";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#F89818",
    "&:hover": {
      backgroundColor: alpha("#F89818", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#F89818",
  },
}));

function FAQ() {
  const { allFaqList, faqToggle, selectedFiler, setSelectedFiler } = useSettingsStore();
  const { t } = useTranslation();
  const [tempFaqList, setTempFaqList] = useState([]);

  const handleChange = (targetFilter) => {
    setSelectedFiler(targetFilter);
    if (targetFilter !== "all_faq") {
      setTempFaqList(allFaqList.filter((item) => item.app_type === targetFilter ? item : ""))
    } else {
      setTempFaqList(allFaqList);
    }
    // console.log("targetFilter::::::", targetFilter);
    // console.log("tempFaqList::::::", tempFaqList);
  };

  const [toggled_accordion, setToggledAccordion] = useState("");
  const [add_faq_modal, setAddFaqModal] = useState(false);
  const [delete_faq_modal, setDeleteFaqModal] = useState(false);
  const [update_faq_modal, setUpdateFaqModal] = useState(false);
  const [faqItem, setFaqItem] = useState(false);

  const addFaqModal = (item) => setAddFaqModal(item);

  const deleteFaqModal = (isShow, item) => {
    setFaqItem(item.id);
    setDeleteFaqModal(isShow);
  };

  const updateFaqModal = (isShow, item) => {
    setFaqItem(item);
    setUpdateFaqModal(isShow);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle('Limadi | Settings')
    getAllFAQ()
  }, []);

  useEffect(() => { handleChange("all_faq") }, [allFaqList])

  return (
    <div>
      <div className="card flex flex-row justify-between  align-middle">
        <div className="flex items-center w-2/3">
          <div className="text-fs24 font-bold mt-[5px] mr-5">
            {t("Frequently Ask Question")} (FAQ)
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between pt-10">
        {/* blue: filter selection */}
        <div className=" w-s250">
          <select
            onChange={(e) => handleChange(e.target.value)}
            name="app_type"
            placeholder="Select App Type"
            className="bg-gray-100 capitalize cursor-pointer border-cInputBorder rounded-lg w-full p-2.5 border-r-[10px] border-r-transparent"
            aria-label="Select App Type"
          >
            {/* <option>Select App Type</option> */}
            <option value="all_faq" selected={selectedFiler === "all_faq" ? true : false} >All FAQ</option>
            <option value="company" selected={selectedFiler === "company" ? true : false} >Company</option>
            <option value="customer" selected={selectedFiler === "customer" ? true : false} >Customer</option>
            <option value="driver" selected={selectedFiler === "driver" ? true : false} >Driver</option>
          </select>
        </div>
        <CommonButtonOutlined btnLabel={t("Add FAQ")} onClick={() => addFaqModal(true)} type="submit" />

      </div>

      <div className="pt-5">
        {tempFaqList?.length > 0 ?
          tempFaqList?.map((item, index) => (
            <Accordion
              key={index}
              accordion_type="dependent"
              isInitOpen={toggled_accordion === item.id ? true : false}
              customOnClick={() => setToggledAccordion(item.id)}
              header={
                <div className="card flex flex-row justify-between align-middle ">
                  <div className="text-fs16 font-semibold pb-2 capitalize max-w-[400px] min-w-[400px] truncate">
                    {item.title}
                  </div>
                </div>
              }
              rightIconWidth="w-[115px]"
              rightEndIcon={
                <div className="flex items-center">
                  <Tooltip title="Toggle this FAQ" >
                    <GreenSwitch
                      size="small"
                      checked={
                        faqToggle.data &&
                        faqToggle.data.id === item.id &&
                        faqToggle.data.is_active
                      }
                      onChange={() => handleFAQToggle(item.id)}
                    />
                  </Tooltip>

                  <Tooltip title="Edit this FAQ" >
                    <img src={EditIcon} alt="" onClick={(e) => {
                      e.preventDefault();
                      updateFaqModal(true, item);
                    }} className="w-s20 h-s20 object-cover ml-s5 mr-s10" />
                  </Tooltip>
                  <Tooltip title="Delete this FAQ">
                    <img src={DeleteIcon} alt="" onClick={() => deleteFaqModal(true, item)} className="w-s18 object-cover" />
                  </Tooltip>
                </div>
              }
              body={
                <div className="max-h-s250 overflow-y-auto text-fs14 text-[#595959]">
                  {item.description}
                </div>
              }
            />
          ))
          : <NA />}
      </div>

      <AddFAQModal show_add_faq_modal={add_faq_modal} setShowAddFaqModal={setAddFaqModal} />

      <DeleteFAQModal show_delete_faq_modal={delete_faq_modal} setShowDeleteFaqModal={setDeleteFaqModal} item={faqItem} />

      <UpdateFAQModal show_update_faq_modal={update_faq_modal} setShowUpdateFaqModal={setUpdateFaqModal} item={faqItem} />

    </div>
  );
}

export default FAQ;
