import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { updateFAQ } from "../../../App/stores/SettingsStore";
import CommonButton from "../../../Components/Button/CommonButton";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonLabel from "../../../Components/Label/CommonLabel";
import CommonModal from "../../../Components/Modal/CommonModal";

export const UpdateFAQModal = ({
  show_update_faq_modal = false,
  setShowUpdateFaqModal,
  item,
}) => {
  const [form, setForm] = React.useState({
    title: "",
    description: "",
    app_type: "",
  });
  const { t } = useTranslation();

  const modalClose = () => {
    setShowUpdateFaqModal(false);
    //   console.log("form: ", form);
    setForm({ id: "", title: "", description: "", app_type: "" });
  };
  const handleChange = (e) => {
    // console.log(e.target.value)
    const value = e.target.value;
    const name = e.target.name;
    setForm({ ...form, [name]: value });
    // console.log("form: ", form);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("form value: ", form);
    updateFAQ(form);
    modalClose();
  };
  useEffect(() => {
    if (item) {
      setForm(item);
    }
  }, [item]);

  return (

    <CommonModal
      showModal={show_update_faq_modal}
      setShowModal={setShowUpdateFaqModal}
      modalTitle={t("Update FAQ")}
      mainContent={
        <div className="pt-5">

          <div className="h-auto">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row justify-between items-center">
                <div className="text-left w-full">
                  <CommonInput
                    required={true}
                    className=""
                    label={t("Question")}
                    placeholder={t("Write Question....")}
                    name="title"
                    type="text"
                    value={form.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="px-s20"></div>

                <div className=" w-full">
                  <div className="pb-s15">
                    <CommonLabel
                      required={true}
                      labelText={t("Select App Type")}
                    />
                  </div>
                  <select
                    required={true}
                    onChange={handleChange}
                    name="app_type"
                    placeholder={t("Select App Type")}
                    className="bg-gray-100
                      text-black
                       capitalize cursor-pointer
                       border-cInputBorder
                       rounded-lg w-full 
                       p-2.5 border-r-[10px] 
                       border-r-transparent"
                    aria-label={t("Select App Type")}
                  >
                    {/* <option>Select App Type</option> */}
                    <option
                      className="text-fs16 text-black"
                      value="company"
                      selected={item.app_type === "company" ? true : false}
                    >
                      Company
                    </option>
                    <option
                      className="text-fs16 text-black"
                      value="customer"
                      selected={item.app_type === "customer" ? true : false}
                    >
                      Customer
                    </option>
                    <option
                      className="text-fs16 text-black"
                      value="driver"
                      selected={item.app_type === "driver" ? true : false}
                    >
                      Driver
                    </option>
                  </select>
                </div>

              </div>

              <div className="pt-5"></div>

              <CommonInput
                required={true}
                textarea={true}
                max_char_limit={500}
                is_show_char_limit={true}
                rows={4}
                label={t("Answer")}
                name="description"
                value={form.description}
                onChange={handleChange}
                placeholder={t("FAQ Answer ...")}
              />

              <div className="flex flex-row justify-between pt-s14">
                <CommonButton
                  colorType="danger"
                  btnLabel={t("Cancel")}
                  type={"button"}
                  onClick={() => modalClose()}
                />
                <CommonButton
                  type="submit"
                  btnLabel={t("Save Changes")}
                />
              </div>
            </form>
          </div>
        </div>
      }
    />

  );
};
