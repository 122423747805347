
import useContactStore from '../../App/stores/ContactStore';
import useDashboardStore from '../../App/stores/DashboardStore';
import { iDemoUser } from '../../Utility/Sources';
import { BaseUrlSrc } from '../../Utility/url';
import { formatDate } from '../../Utility/UtilityFunctions';
import CommonImage from '../Image/CommonImage';


const NewMessageTableRow = ({ data, index }) => {
  const { setShowNewMsgReply, setMessageUserID, setMessage } = useDashboardStore();
  const { setSelectedMessageID } = useContactStore();

  return (
    <>
      <tr
        onClick={() => {
          setShowNewMsgReply(true);
          setMessage(data);
          setMessageUserID(data?.user?.id);
          setSelectedMessageID(data?.id);
        }}
        className={`border-collapse cursor-pointer border-b-[1px] border-cNmSelect hover:bg-cGridView`} >
        <th className='py-s10 border-r-[1px] text-center p-s10 m-2'>
          <span className='mr-3 dm:mr3 md:mr-0'>{index + 1}</span>
        </th>

        <td className='border-r-[1px] p-s10 text-left max-w-[180px] min-w-[180px] truncate'>
        <div className='flex items-center'>
          <div className='mx-s5 max-w-[40px] min-w-[40px]'>
            <CommonImage className='rounded-full w-s40 h-s40 grow-0' src={data?.user?.image} alt="" />
          </div>
          <div className='items-center pl-s10 truncate'>{data?.user?.name}</div>
        </div>
      </td>

        <td className='py-s10 border-r-[1px] px-s10 truncate text-center text-fs14 text-cTextBody  max-w-[150px] min-w-[150px]'>
          <>
            {data?.subject}
          </>
        </td>

        <td className='truncate max-w-[200px] min-w-[200px] py-s10 border-r-[1px] pl-s10 text-fs14 text-center text-cTextBody'>
          <>
            {data?.message}
          </>
        </td>

        <td className='py-s10 border-r-[1px] pl-s10 text-fs14 text-center  text-cTextBody'>
          {formatDate(data?.created_at)}
        </td>

        <td className='py-s10 border-r-[1px] px-s10 text-center'>
          {formatDate(data?.updated_at)}
        </td>

      </tr>
    </>

  )
}


export default NewMessageTableRow;